import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { Warning, AlertBordered } from 'Components';
import { DELETE_DEVICE_MUTATION, GET_DEVICES_QUERY, GET_DEVICE_INTEGRATIONS, ROUTES } from 'Constants';
import { showToastSuccess } from 'Utils';

import * as Styled from './styled';

export const DeviceDeleteForm = ({ device }) => {
    const history = useHistory();

    const { data: deviceIntegrationsData, loading: loadingIntegration } = useQuery(GET_DEVICE_INTEGRATIONS, {
        variables: { tokenList: [device.token] },
        fetchPolicy: 'network-only',
    });
    const integrations = deviceIntegrationsData?.getIntegrationsByDeviceTokens ?? [];

    const [deleteDevice, { loading: isLoading }] = useMutation(DELETE_DEVICE_MUTATION, { refetchQueries: [GET_DEVICES_QUERY] });

    const title = `I understand that device ${device.description} and all received signal data will be deleted`;
    const warningMessage = 'Beware that this device and all received signal data will be deleted. '
        + 'This action cannot be undone!';
    const forbiddenMessage = 'You can not remove device which has integration linked.'
        + 'Please make sure there are no integrations related to this device before its removal';

    const [agreedForDeletion, setAgreedForDeletion] = useState(false);

    const handleCheckbox = (event) => {
        const checked = event.target.checked;

        setAgreedForDeletion(checked);
    };

    const onClick = () => {
        deleteDevice({
            variables: { deviceId: device._id },
            update: () => {
                history.push(ROUTES.devices.main);
                showToastSuccess('Device was deleted successfully');
            },
        });
    };

    return (
        <>
            {
                !loadingIntegration && !integrations.length ? (
                    <Warning
                        warn={warningMessage}
                        checked={agreedForDeletion}
                        handleCheckbox={handleCheckbox}
                        title={title}
                    />
                ) : (
                    <AlertBordered
                        message={forbiddenMessage}
                        title="Forbidden"
                    />
                )
            }
            <Styled.Button
                disabled={!agreedForDeletion || loadingIntegration || integrations.length}
                onClick={onClick}
                loading={isLoading}
            >
                Delete device
            </Styled.Button>
        </>
    );
};

DeviceDeleteForm.propTypes = {
    device: PropTypes.object.isRequired,
};
