import styled from 'styled-components';

export const Logo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 180px;
    height: 100%;
`;

export const LogoImage = styled.img`
    object-fit: contain;
    width: 109px;
`;
