import { ReactComponent as UsbIcon } from 'Assets/icons/usb.svg';
import { ReactComponent as DeviceIcon } from 'Assets/icons/device.svg';
import { ReactComponent as TrashIcon } from 'Assets/icons/trash.svg';

export const getActionButtons = (handlers) => [
    {
        label: 'Add via USB',
        icon: UsbIcon,
        onClick: handlers.addViaUsb,
    },
    {
        label: 'Add existing',
        icon: DeviceIcon,
        onClick: handlers.addExisting,
        cyData: 'project-add-existing-device',
    },
    {
        label: 'Unlink',
        icon: TrashIcon,
        onClick: handlers.unlink,
    },
];
