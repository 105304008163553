export const defaultVisualOptions = {
    clickableIcons: false,
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    height: '100%',
};
