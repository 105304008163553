import { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

import { moveItemInArray } from 'Utils';
import { WIFI, LTE, LORA, ETHERNET, SIGFOX, MODAL } from 'Constants';
import { ReactComponent as ArrowIcon } from 'Assets/network/right-arrow.svg';

import { Modal, WiFiModal, LTEModal, LoRaModal, EthernetModal, SigfoxModal } from '../modals';
import { NetworkDraggable } from '../networkDraggable';
import { WifiSelectForm } from './wifiSelectForm';
import { LteSelectForm } from './lteSelectForm';
import { LoraSelectForm } from './loraSelectForm';
import { EthernetSelectForm } from './ethernetSelectForm';
import { Sigfox } from './sigfox';

import * as Styled from './styled';

export const DraggableSort = ({
    initialValues,
    setNetworks,
    networks,
    wifiList,
    ethernetList,
    loraSettings,
    lteList,
    sigfoxSettings,
    formValues,
    setFormValues,
    disableLora,
}) => {
    const [openedWifiModal, setOpenedWifiModal] = useState(false);
    const [openedLteModal, setOpenedLteModal] = useState(false);
    const [openedLoraModal, setOpenedLoraModal] = useState(false);
    const [openedEthernetModal, setOpenedEthernetModal] = useState(false);
    const [openedSigfoxModal, setOpenedSigfoxModal] = useState(false);

    const sigfoxCredentials = sigfoxSettings?.credentials;
    const sigfoxContractType = sigfoxSettings?.other?.contractType;

    const onSelectFormsChange = (values) => {
        setFormValues({
            ...formValues,
            ...values,
        });
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const reOrderedNetworks = moveItemInArray(
            networks,
            result.source.index,
            result.destination.index,
        );

        setNetworks(reOrderedNetworks);
    };

    const checkedHandler = (id) => {
        const newNetworks = [...networks];
        const selectedIndex = newNetworks.findIndex((network) => network.key === id);
        const selectedItem = {
            ...newNetworks[selectedIndex],
            checked: !newNetworks[selectedIndex].checked,
        };
        newNetworks[selectedIndex] = selectedItem;

        if (id === WIFI && !wifiList.length && selectedItem.checked) {
            setOpenedWifiModal(true);
        }

        if (id === LORA && !loraSettings?.loraServer && selectedItem.checked) {
            setOpenedLoraModal(true);
        }

        if (id === LTE && !lteList.length && selectedItem.checked) {
            setOpenedLteModal(true);
        }

        if (id === ETHERNET && !ethernetList.length && selectedItem.checked) {
            setOpenedEthernetModal(true);
        }

        if (id === SIGFOX && !sigfoxCredentials?.login && !sigfoxCredentials?.password) {
            setOpenedSigfoxModal(true);
        }

        setNetworks(newNetworks);
    };

    const closeModals = () => {
        setOpenedWifiModal(false);
        setOpenedLteModal(false);
        setOpenedLoraModal(false);
        setOpenedEthernetModal(false);
        setOpenedSigfoxModal(false);
    };

    const handleModalClose = (modalType) => {
        if (modalType === WIFI && !wifiList.length) {
            checkedHandler(WIFI);
        }

        if (modalType === LORA && !loraSettings?.loraServer) {
            checkedHandler(LORA);
        }

        if (modalType === LTE && !lteList.length) {
            checkedHandler(LTE);
        }

        if (modalType === ETHERNET && !ethernetList.length) {
            checkedHandler(ETHERNET);
        }

        if (modalType === SIGFOX && !sigfoxCredentials?.login) {
            checkedHandler(SIGFOX);
        }

        closeModals();
    };

    const wifiFormRender = (checked) => Boolean(checked && wifiList?.length) && (
        <Styled.SelectsWrapper>
            <Styled.Arrow>
                <ArrowIcon />
            </Styled.Arrow>
            <WifiSelectForm
                onChange={onSelectFormsChange}
                wifiList={wifiList}
                initialValue={initialValues.wifiId}
            />
        </Styled.SelectsWrapper>
    );

    const ethernetFormRender = (checked) => Boolean(checked && ethernetList?.length) && (
        <Styled.SelectsWrapper>
            <Styled.Arrow>
                <ArrowIcon />
            </Styled.Arrow>
            <EthernetSelectForm
                onChange={onSelectFormsChange}
                ethernetList={ethernetList}
                initialValue={initialValues.ethernet}
            />
        </Styled.SelectsWrapper>
    );

    const lteFormRender = (checked) => Boolean(checked && lteList?.length) && (
        <Styled.SelectsWrapper>
            <Styled.Arrow>
                <ArrowIcon />
            </Styled.Arrow>
            <LteSelectForm
                onChange={onSelectFormsChange}
                lteList={lteList}
                initialValue={initialValues.lteId}
            />
        </Styled.SelectsWrapper>
    );

    const loraFormRender = (checked) => Boolean(checked && loraSettings?.loraServer) && (
        <Styled.SelectsWrapper>
            <Styled.Arrow>
                <ArrowIcon />
            </Styled.Arrow>
            <LoraSelectForm
                onChange={onSelectFormsChange}
                loraSettings={loraSettings}
                initialValues={{
                    loraServer: initialValues.loraServer,
                    loraType: initialValues.loraType,
                    loraRegion: initialValues.loraRegion,
                }}
                disableLora={disableLora}
            />
        </Styled.SelectsWrapper>
    );

    const sigfoxFormRender = (checked) => Boolean(checked && sigfoxCredentials?.login) && (
        <Styled.SelectsWrapper>
            <Styled.Arrow>
                <ArrowIcon />
            </Styled.Arrow>
            <Sigfox contractType={sigfoxContractType} />
        </Styled.SelectsWrapper>
    );

    const renderNetwork = (network, index) => {
        let formComponent = null;
        let disabledCheckbox = false;

        switch (network.key) {
            case WIFI:
                formComponent = wifiFormRender(network.checked);
                break;
            case LTE:
                formComponent = lteFormRender(network.checked);
                break;
            case ETHERNET:
                formComponent = ethernetFormRender(network.checked);
                break;
            case LORA:
                formComponent = loraFormRender(network.checked);
                disabledCheckbox = disableLora;
                break;
            case SIGFOX:
                formComponent = sigfoxFormRender(network.checked);
                break;
            default:
                break;
        }

        return (
            <Draggable draggableId={network.key} index={index} key={network.key}>
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <Styled.DragItemWrapper>
                            <NetworkDraggable
                                id={network.key}
                                title={network.label}
                                provided={provided}
                                checkedAction={checkedHandler}
                                checked={network.checked}
                                disabled={disabledCheckbox}
                                cyData={`${network.key}-network-select`}
                            />
                            {formComponent}
                        </Styled.DragItemWrapper>
                    </div>
                )}
            </Draggable>
        );
    };

    return (
        <>
            <Styled.SortWrapper>
                <Styled.NumberItemsWrapper>
                    {networks.map((item, index) => (
                        <Styled.ItemNumber key={item.key}>
                            {index + 1}
                        </Styled.ItemNumber>
                    ))}
                </Styled.NumberItemsWrapper>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {networks.map(renderNetwork)}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Styled.SortWrapper>
            <Modal handleClose={() => handleModalClose(WIFI)} isOpened={openedWifiModal}>
                <WiFiModal
                    handleClose={() => handleModalClose(WIFI)}
                    handleSuccess={closeModals}
                />
            </Modal>
            <Modal handleClose={() => handleModalClose(LTE)} isOpened={openedLteModal}>
                <LTEModal
                    handleClose={() => handleModalClose(LTE)}
                    handleSuccess={closeModals}
                />
            </Modal>
            <Modal handleClose={() => handleModalClose(LORA)} isOpened={openedLoraModal}>
                <LoRaModal
                    handleClose={() => handleModalClose(LORA)}
                    handleSuccess={closeModals}
                    typeOfForm={MODAL}
                />
            </Modal>
            <Modal handleClose={() => handleModalClose(ETHERNET)} isOpened={openedEthernetModal}>
                <EthernetModal
                    handleClose={() => handleModalClose(ETHERNET)}
                    handleSuccess={closeModals}
                />
            </Modal>
            <Modal handleClose={() => handleModalClose(SIGFOX)} isOpened={openedSigfoxModal}>
                <SigfoxModal
                    handleClose={() => handleModalClose(SIGFOX)}
                    handleSuccess={closeModals}
                    typeOfForm={MODAL}
                />
            </Modal>
        </>
    );
};

DraggableSort.propTypes = {
    initialValues: PropTypes.object,
    networks: PropTypes.arrayOf(PropTypes.object).isRequired,
    setNetworks: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    setFormValues: PropTypes.func.isRequired,
    wifiList: PropTypes.arrayOf(PropTypes.object),
    ethernetList: PropTypes.arrayOf(PropTypes.object),
    loraSettings: PropTypes.object,
    lteList: PropTypes.arrayOf(PropTypes.object),
    sigfoxSettings: PropTypes.object,
    disableLora: PropTypes.bool,
};

DraggableSort.defaultProps = {
    initialValues: {},
    wifiList: [],
    ethernetList: [],
    loraSettings: {},
    lteList: [],
    sigfoxSettings: {},
    disableLora: false,
};
