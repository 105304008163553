import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useQuery } from '@apollo/client';

import { ReactComponent as Github } from 'Assets/icons/github-fill.svg';
import { PageHeader, SearchInput, Button, Spin, RangeDisplayItems } from 'Components';
import { GET_USER_PROFILE_QUERY, pymakrHeader, ROUTES } from 'Constants';
import { GithubService, VaultService } from 'Services';
import { showToastError, prepareEditorStructure } from 'Utils';

import { ItemList } from './components';
import { useDebounce } from './useDebounce';

import * as Styled from './styled';

export const PymakrGitHub = () => {
    const history = useHistory();

    const { data: userData } = useQuery(GET_USER_PROFILE_QUERY);
    const storageRef = useRef();
    const [searchString, setSearchString] = useState('');
    const [customPagination, setCustomPagination] = useState({
        type: '',
        value: '10',
    });
    const [repositories, setRepositories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState(null);
    const [message, setMessage] = useState('');
    const [maxCounter, setMaxCounter] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFilesLoading, setIsFilesLoading] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedRepository, setSelectedRepository] = useState(null);
    const [branches, setBranches] = useState([]);
    const [preparedProject, setPreparedProject] = useState(null);
    const debounceSearchString = useDebounce(searchString, 1500);
    const user = userData?.getUserProfile?.owner;

    const getArrayOfBranches = async () => {
        if (isEmpty(selectedRepository)) {
            return;
        }
        try {
            const arrayOfBranches = await GithubService.getBranches(
                selectedRepository.full_name,
            );
            const branchesForSelect = arrayOfBranches.map((branch) => ({
                label: branch.name,
                value: branch.name,
                ...branch,
            }));
            setBranches(branchesForSelect);
        } catch (error) {
            showToastError(error?.message);
        }
    };

    const getCurrentRepository = () => {
        if (!selectedItem) {
            return;
        }
        const repository = repositories.find(
            (item) => item.id === selectedItem,
        );
        setSelectedRepository(repository);
    };

    const downloadRepository = async () => {
        if (!selectedBranch) {
            return;
        }
        setIsFilesLoading(true);
        const currentBranch = branches.find(
            (branch) => branch.name === selectedBranch.value,
        );
        try {
            const response = await GithubService.getTreeOfRepository(
                selectedRepository.owner.login,
                selectedRepository.name,
                currentBranch.commit.sha,
            );
            const project = prepareEditorStructure(
                selectedRepository.name,
                response,
                user,
            );
            setPreparedProject(project);
        } catch (error) {
            showToastError(error?.message);
        } finally {
            setIsFilesLoading(false);
        }
    };

    const getRepositoriesFromGithub = async () => {
        if (debounceSearchString?.length) {
            setIsLoading(true);
            try {
                const result = await GithubService.getRepositories(
                    debounceSearchString,
                    currentPage,
                    customPagination.value,
                );
                if (isEmpty(result?.items)) {
                    setRepositories([]);
                    setMessage('Repositories are not found...');
                } else {
                    const { items, total } = result;
                    setRepositories(items);
                    setMaxCounter(Math.ceil(total / +customPagination.value));
                    setMessage('');
                }
            } catch (error) {
                showToastError(error?.message);
            } finally {
                setIsLoading(false);
            }
        } else {
            setRepositories([]);
        }
    };

    useEffect(() => {
        if (!storageRef.current) {
           storageRef.current = new VaultService();
        }
    }, []);

    useEffect(() => {
        getArrayOfBranches();
        if (selectedRepository) {
            storageRef.current.setItem('project', selectedRepository?.name);
        }
    }, [selectedRepository]);

    useEffect(() => {
        getCurrentRepository();
    }, [selectedItem]);

    useEffect(() => {
        downloadRepository();
    }, [selectedBranch]);

    useEffect(() => {
        getRepositoriesFromGithub();
    }, [debounceSearchString, currentPage, customPagination.value]);

    useEffect(() => {
        if (user && preparedProject) {
            const key = `${user}-${selectedRepository?.name}`;
            const currentValue = storageRef.current.getItem(key) || {};
            storageRef.current.setItem(key, { ...currentValue, hierarchy: preparedProject });
            history.push(`${ROUTES.pymakr.github}/${selectedRepository?.name}`);
        }
    }, [user, preparedProject]);

    const handleSearchString = (string) => {
        setSearchString(string);
    };

    const handleCustomPagination = (page) => {
        setCustomPagination(page);
    };

    const nextPage = () => {
        if (currentPage >= maxCounter) {
            setCurrentPage(maxCounter);
        } else {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        } else {
            setCurrentPage(1);
        }
    };

    return (
        <Spin spinning={isFilesLoading}>
            <PageHeader
                title={pymakrHeader.title}
                description={[pymakrHeader.description]}
            />
            <Styled.PageContent>
                <Styled.Title>Look for the GitHub repository</Styled.Title>
                <Styled.RowSearch>
                    <Github />
                    <SearchInput
                        placeholder="Search for GitHub repository"
                        widthField={620}
                        onSearch={handleSearchString}
                    />
                </Styled.RowSearch>
                <Styled.Message>{message}</Styled.Message>
                {isLoading && (
                    <>
                        <Styled.Loading active block />
                        <Styled.Loading active block />
                        <Styled.Loading active block />
                    </>
                )}
                {!isLoading && (
                    <Styled.List>
                        {repositories &&
                            repositories.map((item) => (
                                <ItemList
                                    key={item.id}
                                    id={item.id}
                                    image={item.owner.avatar_url}
                                    title={item.full_name}
                                    isChecked={item.id === selectedItem}
                                    description={item.description}
                                    url={item.html_url}
                                    setSelectedItem={setSelectedItem}
                                    setSelectedBranch={setSelectedBranch}
                                    branches={branches}
                                />
                            ))}
                    </Styled.List>
                )}
                {!isEmpty(repositories) && (
                    <Styled.Footer>
                        <Styled.Buttons>
                            <Button
                                buttonType="transparent"
                                onClick={previousPage}
                                disabled={currentPage === 1}
                            >
                                Back
                            </Button>
                            <Button
                                onClick={nextPage}
                                disabled={maxCounter === currentPage}
                            >
                                Next
                            </Button>
                        </Styled.Buttons>
                        <RangeDisplayItems
                            title="Repositories on a page :"
                            selectedItem={customPagination.value}
                            handleRange={handleCustomPagination}
                        />
                    </Styled.Footer>
                )}
            </Styled.PageContent>
        </Spin>
    );
};
