import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES, pymakrHeader, DISABLED_FEATURES } from 'Constants';
import { Tile, PageHeader, Modal } from 'Components';
import { ReactComponent as BlankProject } from 'Assets/icons/pymakr.svg';
import { ReactComponent as Project } from 'Assets/icons/computer.svg';
import { ReactComponent as Zip } from 'Assets/icons/zip.svg';
import { ReactComponent as Github } from 'Assets/icons/github.svg';
import { ReactComponent as MicroPython } from 'Assets/images/micro-python.svg';
import { ReactComponent as CPlusPlus } from 'Assets/images/c++.svg';

import * as Styled from './styled';

export const Pymakr = () => {
    const history = useHistory();
    const [modalOpen, setModalOpen] = useState(false);
    const isShowCompilation = !DISABLED_FEATURES.includes('compilation');

    const { title, description } = pymakrHeader;
    const subtitle = 'What do you want to open in CtrlR?';

    const buttons = [
        {
            header: 'Blank Project',
            icon: <BlankProject />,
            title: 'Empty project',
            route: ROUTES.pymakr.main,
        },
        {
            header: 'Project',
            icon: <Project />,
            title: 'Import from an existing project',
            route: ROUTES.pymakr.project,
        },
        {
            header: 'ZIP File',
            icon: <Zip />,
            title: 'Upload a Zip file',
            route: ROUTES.pymakr.zipFile,
        },
        {
            header: 'Github Repository',
            icon: <Github />,
            title: 'Import from a GitHub branch',
            route: ROUTES.pymakr.github,
        },
    ];

    const onModalClose = () => {
        setModalOpen(false);
        history.push(ROUTES.pymakr.main);
    };

    const handleOnModalBtnClick = (language) => {
        setModalOpen(false);
        history.push(`${ROUTES.pymakr.blankProject}/${language}`);
    };

    const handleClick = (index) => {
        if (index) {
            return null;
        }
        if (isShowCompilation) {
            setModalOpen(true);
        } else {
            handleOnModalBtnClick('MicroPython');
        }
    };

    return (
        <Styled.Wrapper>
            <PageHeader title={title} description={[description]} />
            <h1>{subtitle}</h1>
            <Styled.PymakrList>
                {buttons.map((tile, index) => (
                    <div key={tile.header}>
                        <Styled.TitleBox title={tile.header} />
                        <Styled.TileWrapper>
                            <Tile
                                onClick={() => handleClick(index)}
                                title={tile.title}
                                links={[tile.route]}
                                icon={tile.icon}
                                isNew
                            />
                        </Styled.TileWrapper>
                    </div>
                ))}
            </Styled.PymakrList>
            <Modal isOpened={modalOpen} handleClose={onModalClose}>
                <Styled.ModalWrapper>
                    <h1>What language do you want to use?</h1>
                    <Styled.ModalButtonsWrapper>
                        <Styled.MicroPython
                            onClick={() => handleOnModalBtnClick('MicroPython')}
                        >
                            <MicroPython />
                            <span>MicroPython</span>
                        </Styled.MicroPython>
                            <Styled.CPlusPlus
                                onClick={() => handleOnModalBtnClick('C++')}
                            >
                                <CPlusPlus />
                                <span>C++</span>
                            </Styled.CPlusPlus>
                    </Styled.ModalButtonsWrapper>
                    <Styled.ModalCloseBtn
                        buttonType="transparent"
                        onClick={onModalClose}
                    >
                        Close
                    </Styled.ModalCloseBtn>
                </Styled.ModalWrapper>
            </Modal>
        </Styled.Wrapper>
    );
};
