import { useContext } from 'react';
import { Switch } from 'react-router-dom';
import { Menu, RouteWrapper } from 'Components';
import { ROUTES } from 'Constants';
import { ThemeContext } from 'styled-components';
import { UserLogs } from './userLogs';
import { UserTokens } from './userTokens';

import * as Styled from './styled';

const links = [
    { title: 'User Logs', route: ROUTES.settings.openApi.logs },
    { title: 'Tokens', route: ROUTES.settings.openApi.tokens },
];

export const PublicApi = () => {
    const styledTheme = useContext(ThemeContext);
    const description = `Public API will help you to
    launch the core ${styledTheme.whiteLabelCompany} functionality in your own environment.`;

    return (
        <Styled.Wrapper>
            <Styled.HeaderText>
                {description}
            </Styled.HeaderText>
            <Menu links={links}>
                <Switch>
                    <RouteWrapper
                        path={ROUTES.settings.openApi.logs}
                        render={(props) => <UserLogs {...props} />}
                    />
                    <RouteWrapper
                        path={ROUTES.settings.openApi.tokens}
                        render={(props) => <UserTokens {...props} />}
                    />
                </Switch>
            </Menu>
        </Styled.Wrapper>
    );
};
