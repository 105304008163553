import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { Modal } from 'Components';

import { FormContent } from './formContent';
import * as Styled from './styled';
import { getValidationSchema } from './config';

export const ProjectNameModal = ({
    isOpen,
    onClose,
    projects,
    onSubmit,
    initialValues,
}) => {
    const checkIsNameNotDuplicated = (name) => !projects.some((project) => project.name === name);

    const combinedInitialValues = useMemo(() => ({ name: initialValues.name || '' }), []);

    return (
        <Modal isOpened={isOpen} handleClose={onClose}>
            <Styled.Modal>
                <Styled.Title>
                    New Fast  IoT Project
                </Styled.Title>
                <Styled.Wrapper>
                    <Styled.Description>
                        Please fill details of your new project:
                    </Styled.Description>
                    <Formik
                        initialValues={combinedInitialValues}
                        validationSchema={getValidationSchema(checkIsNameNotDuplicated)}
                        onSubmit={onSubmit}
                        validateOnMount
                    >
                        {(props) => (
                            <FormContent
                                {...props}
                                onCancel={onClose}
                            />
                        )}
                    </Formik>
                </Styled.Wrapper>
            </Styled.Modal>
        </Modal>
    );
};

ProjectNameModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    projects: PropTypes.array.isRequired,
    initialValues: PropTypes.object.isRequired,
};
