import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.div`
    
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.75rem;
`;

export const Title = styled.div`
    font-size: 36px;
    color: ${({ theme }) => theme.colors.title};
    margin-right: 33px;
`;

export const List = styled.div`
    display: flex;
    width: fit-content;
`;

export const Item = styled(NavLink)`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.title};
    font-size: 16px;
    font-weight: 600;
    border-radius: 11px;
    margin-right: 38px;
    height: 40px;
    min-width: 140px;
    padding: 10px 16px;
    transition: color 0.3s, border-color 0.3s, background 0.3s;

    svg {
        font-size: 20px;
        margin-right: 10px;
    }

    &:last-child {
        margin-right: 0;
    }

    &:hover,
    &.active {
        color: ${({ theme }) => theme.colors.activeText};
        border-color: ${({ theme }) => theme.colors.active_light};
        background: ${({ theme }) => theme.colors.active_light};
    }
`;
