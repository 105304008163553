import { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/client';
import moment from 'moment';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import {
    ROUTES,
    pymakrStorageLimits,
    DELETE_FCOTA_FILE_MUTATION,
    BUTTON_C,
    BUTTON_MICROPYTHON,
    LANGUAGE_C,
    LANGUAGE_MICROPYTHON,
    COMPILATION_STATUS_SUBSCRIPTION_API,
    pymakrCompilationStatuses,
    C_EXTENSIONS_FILES,
} from 'Constants';
import { Modal, DeleteModal } from 'Components';
import {
    showToastError,
    calculateLocalStorageSize,
    clearLocalStorageByCondition,
    renameStoredKey,
    deleteKeysAndTabs,
    clearExpiredData,
    setDeviceModeInfo,
    getReleaseModeInfo,
} from 'Utils';
import { ReactComponent as PymakrIcon } from 'Assets/icons/pymakr.svg';

import {
    CodeContainer,
    ExplorerContainer,
    TerminalContainer,
} from './editorContainers';
import { SaveAndExportCompilation, SaveAndExportMicroPython, SaveAndExportС } from './saveAndExport';
import { PingMark } from './pingMark';
import { useContextSelector, useSynchronize } from '../pymakrHooks';
import * as Styled from './styled';

export const PymakrEditorApp = ({
    projectKey,
    title,
    initialStructure,
    user,
    deviceToken,
    programmingLanguage,
}) => {
    const client = useApolloClient();
    const [deleteFile] = useMutation(DELETE_FCOTA_FILE_MUTATION);
    const [structure, setStructure] = useState(initialStructure);
    const [language, setLanguage] = useState(programmingLanguage);
    const [titleOfButton, setTitleOfButton] = useState(BUTTON_MICROPYTHON);
    const storedStructure = JSON.parse(localStorage.getItem(projectKey));

    useEffect(() => {
        if (structure?.files?.length && storedStructure?.hierarchy.files) {
            const files = structure?.files || storedStructure?.hierarchy.files;
            const isExistCpp = files.some(
                (file) => C_EXTENSIONS_FILES.includes(file?.name.slice(file.name.lastIndexOf('.'))),
            );
            setLanguage(isExistCpp ? LANGUAGE_C : LANGUAGE_MICROPYTHON);
            if (isExistCpp) {
                setTitleOfButton(BUTTON_C);
            }
        }
    }, [structure, storedStructure]);

    const defaultTabs = useMemo(() => {
        let initialOpenedFile = null;
        const tabs = [];

        if (
            storedStructure &&
            moment(storedStructure.expires).isAfter(moment())
        ) {
            const firstLevelFiles = storedStructure.hierarchy.files.filter(
                (item) => item.type === 'file' && item.name,
            );
            if (firstLevelFiles.length) {
                initialOpenedFile = firstLevelFiles[0];
                tabs.push({ ...initialOpenedFile });
            }
        }

        const propFiles = structure.files?.filter((i) => i.type === 'file' && i.name);
        if (propFiles?.length && !tabs.length) {
            tabs.push({ ...propFiles[0] });
        }
        return tabs;
    }, [structure, storedStructure]);

    const [offerMemoryReset, setOfferMemoryReset] = useState(false);
    const [saveAndExportModalOpen, setSaveAndExportModalOpen] = useState(false);
    const [syncModalOpen, setSyncModalOpen] = useState(false);
    const [autoModalCompilationOpen, setAutoModalCompilationOpen] = useState(false);

    const onCloseResetModal = () => setOfferMemoryReset(false);
    const onSaveAndExportModalClose = () => setSaveAndExportModalOpen(false);
    const onSyncModalClose = () => setSyncModalOpen(false);
    const onAutoModalCompilationClose = () => setAutoModalCompilationOpen(false);

    const editorSlice = useContextSelector('editor');
    const { refreshComplete, refreshEditor } = editorSlice.handlers;

    const colorizerSlice = useContextSelector('colorizer');
    const { updatedFiles } = colorizerSlice.state;
    const { unsetUpdatedFile } = colorizerSlice.handlers;

    const hierarchySlice = useContextSelector('hierarchy');
    const { filesSynchronized, filesCount } = hierarchySlice.state;

    const compilationSlice = useContextSelector('compilation');
    const { compilationId, compilationLogs, compilationStatus } = compilationSlice.state;
    const { setCompilationStatus, setCompilationLogs } = compilationSlice.handlers;

    const tabsSlice = useContextSelector('tabs');
    const { tabs, activeTab } = tabsSlice.state;
    const { setTabs, setActiveTab } = tabsSlice.handlers;

    const editorRef = useRef(null);
    const storageSize = calculateLocalStorageSize();

    useEffect(() => {
        if (defaultTabs.length) {
            setTabs(defaultTabs);
            setActiveTab(defaultTabs[0]);
        }

        if (storageSize > pymakrStorageLimits.reset) {
            setOfferMemoryReset(true);
        }

        if (deviceToken) {
            setDeviceModeInfo(projectKey, title, deviceToken, user);
        }
    }, []);

    const syncHierarchy = useSynchronize(projectKey, {
        setStructure,
        onSyncModalClose,
        setSyncModalOpen,
    });

    const resetStoredData = () => {
        clearLocalStorageByCondition(user);
        refreshComplete();
        setOfferMemoryReset(false);
    };

    const onRename = (previousItem, currentItem) => {
        renameStoredKey(previousItem.key, currentItem.key);
        const currentTabs = cloneDeep(tabs);
        const targetTabIndex = currentTabs.findIndex(
            (i) => i.key === previousItem.key,
        );

        if (targetTabIndex >= 0) {
            currentTabs[targetTabIndex] = currentItem;
        } else {
            currentTabs.push(currentItem);
        }

        setActiveTab(currentItem);
        setTabs(currentTabs);
    };

    const onDelete = (deleteItem) => {
        let currentTabs = cloneDeep(tabs);

        if (deleteItem.type === 'folder') {
            currentTabs = deleteKeysAndTabs(deleteItem.files, currentTabs);
        }

        if (deleteItem.type === 'file') {
            if (deviceToken) {
                deleteFile({
                    variables: { deviceToken, path: deleteItem.path },
                });
            }

            if (updatedFiles.includes(deleteItem.path)) {
                unsetUpdatedFile(updatedFiles, deleteItem.path);
            }
            localStorage.removeItem(deleteItem.key);
            const targetTabIndex = currentTabs.findIndex(
                (item) => item.key === deleteItem.key,
            );

            if (targetTabIndex >= 0) {
                currentTabs.splice(targetTabIndex, 1);
            }
        }

        const newActiveIndex = currentTabs.findIndex(
            (i) => i.key === activeTab.key,
        );

        if (newActiveIndex < 0 && currentTabs.length) {
            setActiveTab(currentTabs[0]);
        }

        if (!currentTabs.length) {
            setActiveTab(null);
        }

        setTabs(currentTabs);
    };

    const onRefresh = () => {
        setTabs(defaultTabs);
        setActiveTab(defaultTabs[0] || null);
        refreshComplete();
    };

    const onClearExpiredData = () => {
        clearExpiredData(user, activeTab?.key, () => refreshEditor());
    };

    const saveAndExport = () => {
        const projectSize = calculateLocalStorageSize(projectKey);
        if (projectSize > pymakrStorageLimits.error) {
            showToastError('Failed. The size of your solution is too big.');
        }
        if (compilationStatus === pymakrCompilationStatuses.succeeded) {
            setAutoModalCompilationOpen(true);
        } else {
            setSaveAndExportModalOpen(true);
        }
    };

    const explorerRefreshHandlers = {
        onRename,
        onDelete,
        onRefresh,
        onClearExpiredData,
    };

    const getLogs = useCallback(async () => {
        if (!compilationId) {
            return;
        }
        try {
            await client.subscribe({
                query: COMPILATION_STATUS_SUBSCRIPTION_API,
                variables: {
                    compilationId,
                    user,
                },
            }).subscribe({
                next({ data }) {
                    const { compilationStatusSubscription__config__: { logs, status } } = data;
                    setCompilationLogs(logs);
                    setCompilationStatus(status || pymakrCompilationStatuses.pending);
                },
            });
        } catch (error) {
            console.warn(error);
        }
    }, [compilationId]);

    useEffect(() => {
        getLogs();
    }, [getLogs]);

    useEffect(() => {
        if (compilationStatus === pymakrCompilationStatuses.succeeded) {
            setAutoModalCompilationOpen(true);
        }
    }, [compilationStatus]);

    const syncCallBack = deviceToken || getReleaseModeInfo(projectKey) ? syncHierarchy : null;

    return (
        <Styled.EditorWrapper ref={editorRef}>
            <Styled.Header>
                <section>
                    <Styled.Breadcrumb
                        role="navigation"
                        aria-label="breadcrumbs"
                    >
                        <Styled.BreadcrumbItem>
                            <Link to={ROUTES.pymakr.main}>CtrlR Start</Link>
                        </Styled.BreadcrumbItem>
                        <Styled.BreadcrumbItem>/</Styled.BreadcrumbItem>
                        <Styled.ActiveBreadcrumb aria-current="page">
                            {title}
                        </Styled.ActiveBreadcrumb>
                    </Styled.Breadcrumb>
                </section>
                {deviceToken ? (
                    <PingMark deviceToken={deviceToken} />
                ) : (
                    <Styled.SaveAndExportBtn
                        onClick={saveAndExport}
                        buttonType="filled"
                        Icon={PymakrIcon}
                    >
                        {titleOfButton}
                    </Styled.SaveAndExportBtn>
                )}
            </Styled.Header>
            <Styled.CodeWrapper>
                <Styled.HorizontalSplit
                    gutterSize={10}
                    sizes={[15, 85]}
                    direction="horizontal"
                    minSize={[200, 700]}
                >
                    <ExplorerContainer
                        heading={title}
                        initialStructure={structure}
                        parentRef={editorRef}
                        explorerRefreshHandlers={explorerRefreshHandlers}
                        projectKey={projectKey}
                        title={title}
                        onClose={onSaveAndExportModalClose}
                        syncHierarchy={syncCallBack}
                        user={user}
                        deviceToken={deviceToken}
                    />
                    <Styled.MainWrapper>
                        {(compilationId || deviceToken) ? (
                        <Styled.VerticalSplit
                            gutterSize={10}
                            direction="vertical"
                            sizes={[50, 50]}
                        >
                            <Styled.MiniWrapper>
                                <CodeContainer projectKey={projectKey} compilationStatus={compilationStatus} />
                            </Styled.MiniWrapper>
                            <Styled.MiniWrapper>
                                <TerminalContainer
                                    deviceToken={deviceToken}
                                    userName={user}
                                    compilationLogs={compilationLogs}
                                    compilationStatus={compilationStatus}
                                />
                            </Styled.MiniWrapper>
                        </Styled.VerticalSplit>
                        ) : <CodeContainer projectKey={projectKey} /> }
                    </Styled.MainWrapper>
                </Styled.HorizontalSplit>
            </Styled.CodeWrapper>
            <Modal isOpened={offerMemoryReset} handleClose={onCloseResetModal}>
                <DeleteModal
                    title="Warning!"
                    description={
                        `You have used ${storageSize}MB of 4MB available memory.` +
                        'Would you like to reset ALL stored data? Make sure to export your progress!'
                    }
                    submitText="Reset"
                    cancelAction={onCloseResetModal}
                    deleteAction={resetStoredData}
                />
            </Modal>
            <Modal
                isOpened={saveAndExportModalOpen}
                handleClose={onSaveAndExportModalClose}
            >
                {language === LANGUAGE_MICROPYTHON && (
                    <SaveAndExportMicroPython
                        projectKey={projectKey}
                        title={title}
                        onClose={onSaveAndExportModalClose}
                    />
                )}
                {language === LANGUAGE_C && (
                    <SaveAndExportС
                        projectKey={projectKey}
                        title={title}
                        onClose={onSaveAndExportModalClose}
                    />
                )}
            </Modal>
            <Modal
                isOpened={autoModalCompilationOpen}
                handleClose={onAutoModalCompilationClose}
            >
                <SaveAndExportCompilation
                    projectKey={projectKey}
                    title={title}
                    onClose={onAutoModalCompilationClose}
                />
            </Modal>
            <Modal isOpened={syncModalOpen} handleClose={() => {}}>
                <Styled.SyncModalWrapper>
                    <Styled.SyncTitle>
                        Synchronization with device
                    </Styled.SyncTitle>
                    <Styled.SyncProgress>
                        {`${filesSynchronized.length} / ${filesCount} Files Synchronized`}
                    </Styled.SyncProgress>
                </Styled.SyncModalWrapper>
            </Modal>
        </Styled.EditorWrapper>
    );
};

PymakrEditorApp.defaultProps = {
    deviceToken: null,
    programmingLanguage: '',
};

PymakrEditorApp.propTypes = {
    title: PropTypes.string.isRequired,
    initialStructure: PropTypes.object.isRequired,
    projectKey: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    deviceToken: PropTypes.string,
    programmingLanguage: PropTypes.string,
};
