import { useState, useRef, useEffect, useContext } from 'react';
import { useQuery, useApolloClient, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

import {
    GET_AZURE_DEVICE_BY_INTEGRATION_ID,
    SEND_AZURE_TEST_MESSAGE,
    GET_USER_PROFILE_QUERY,
    GET_ALL_DEVICES_QUERY,
    UPDATE_AZURE_HUB_TOPIC,
    SAVE_AZURE_DEVICES_MUTATION,
    REMOVE_AZURE_DEVICES_MUTATION,
} from 'Constants';
import {
    showToastError,
    showToastSuccess,
    replaceAccents,
} from 'Utils';
import { Button, Modal, DeleteModal, AzureIntegrationDefinition } from 'Components';

import { DevicesTable } from './devicesTable';
import { DetailsBlock } from './detailsBlock';

import * as Styled from './styled';

const DEFAULT_TEST_MESSAGE = 'Send test message';

export const AzureDetails = ({ details, deleteIntegration, isDeleteLoading }) => {
    const apolloClient = useApolloClient();
    const [testLoading, setTestLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [testMessage, setTestMessage] = useState(DEFAULT_TEST_MESSAGE);
    const [deleteModalOpened, toggleDeleteModal] = useState(false);
    const [isEditionMode, setIsEditionMode] = useState(false);
    const timeoutRef = useRef(null);
    const styledTheme = useContext(ThemeContext);

    const { data: userData } = useQuery(GET_USER_PROFILE_QUERY);
    const user = userData?.getUserProfile ?? {};

    const { data: allDevicesData } = useQuery(
        GET_ALL_DEVICES_QUERY,
        { fetchPolicy: 'cache-and-network' },
    );
    const allDevices = allDevicesData?.device ?? [];

    const { data } = useQuery(
        GET_AZURE_DEVICE_BY_INTEGRATION_ID,
        {
            variables: { integrationId: details._id, includeDevice: true },
            fetchPolicy: 'cache-and-network',
        },
    );

    const azureDevices = data?.getAzureDevicesByIntegrationId || [];

    const [updateTopic] = useMutation(UPDATE_AZURE_HUB_TOPIC);

    const [addAzureDevices] = useMutation(
        SAVE_AZURE_DEVICES_MUTATION,
        { refetchQueries: [GET_AZURE_DEVICE_BY_INTEGRATION_ID] },
    );

    const [removeAzureDevices] = useMutation(
        REMOVE_AZURE_DEVICES_MUTATION,
        { refetchQueries: [GET_AZURE_DEVICE_BY_INTEGRATION_ID] },
    );

    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    const sendTestMessage = async () => {
        setTestLoading(true);

        try {
            const { data: { sendAzureTestMessage } } = await apolloClient.query({
                query: SEND_AZURE_TEST_MESSAGE,
                variables: { integrationId: details?._id },
                fetchPolicy: 'network-only',
            });

            setTestMessage(sendAzureTestMessage.message);

            timeoutRef.current = setTimeout(() => setTestMessage(DEFAULT_TEST_MESSAGE), 5000);
        } catch (error) {
            showToastError(error.message);
        } finally {
            setTestLoading(false);
        }
    };

    const deleteIntegrationHandler = async () => {
        try {
            await deleteIntegration();

            showToastSuccess('Azure integration deleted successfully');
        } catch (error) {
            showToastError(error.message);
        }
    };

    const onEdit = async (values) => {
        setIsLoading(true);
        const { devices, messagesTopic } = values;

        const devicesToAdd = [];
        const devicesToRemove = [];

        allDevices.forEach((device, index) => {
            const { token, description } = device;

            const relatedAzureDevice = azureDevices.find(
                (item) => item.azureDevice?.deviceToken === device.token,
            );

            if (relatedAzureDevice && !devices[index]) {
                devicesToRemove.push(relatedAzureDevice.azureDevice.deviceToken);
            }

            if (devices[index]) {
                const devName = replaceAccents(description).replace(/([^a-z0-9]+)/gi, '-');
                const deviceId = `${devName}-${token}`;

                const deviceData = {
                    owner: user.owner,
                    topic: messagesTopic,
                    deviceToken: token,
                };

                if (relatedAzureDevice) {
                    deviceData._id = relatedAzureDevice.azureDevice._id;
                } else {
                    deviceData.deviceId = deviceId;
                }

                devicesToAdd.push(deviceData);
            }
        });

        try {
            if (messagesTopic !== azureDevices[0]?.azureDevice.topic) {
                await updateTopic({
                    variables: { topic: messagesTopic, integrationId: details?._id },
                });
            }

            if (devicesToAdd.length) {
                await addAzureDevices({
                    variables: {
                        devices: devicesToAdd,
                        integrationId: details?._id,
                        iotHubString: details?.azure.connectionString,
                    },
                });
            }

            if (devicesToRemove.length) {
                await removeAzureDevices({
                    variables: {
                        tokens: devicesToRemove,
                        integrationId: details?._id,
                        iotHubString: details?.azure.connectionString,
                    },
                });
            }

            showToastSuccess('Integration edited successfully');

            setIsEditionMode(false);
        } catch (error) {
            showToastError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    if (isEditionMode) {
        const initialValues = {
            name: details?.name,
            messagesTopic: azureDevices[0]?.azureDevice?.topic,
            deviceTokens: azureDevices.map((item) => item.device?.token),
        };

        return (
            <AzureIntegrationDefinition
                connectionString={details?.azure.connectionString}
                allDevices={allDevices}
                onSubmit={onEdit}
                initialValues={initialValues}
                submitText="Edit"
                onCancel={() => setIsEditionMode(false)}
                isLoading={isLoading}
            />
        );
    }

    return (
        <>
            <DevicesTable azureDevices={azureDevices} />
            {!!azureDevices.length && (
                <DetailsBlock
                    azureDevices={azureDevices}
                    details={details}
                />
            )}
            <Styled.ButtonsWrapper>
                <Button
                    buttonType="transparent"
                    onClick={() => setIsEditionMode(true)}
                >
                    Edit
                </Button>
                <Button onClick={() => toggleDeleteModal(true)}>
                    Delete
                </Button>
            </Styled.ButtonsWrapper>
            <Styled.BigButton
                loading={testLoading}
                onClick={sendTestMessage}
            >
                {testMessage}
            </Styled.BigButton>
            <Modal
                isOpened={deleteModalOpened}
                handleClose={() => toggleDeleteModal(false)}
            >
                <DeleteModal
                    deleteAction={deleteIntegrationHandler}
                    cancelAction={() => toggleDeleteModal(false)}
                    title="Are you sure you want to delete this Integration?"
                    description={`This integration will be deleted from ${styledTheme.whiteLabelCompany},
                        that means that the messages sent from your devices
                        wont be transmitted to your Webhook anymore.`}
                    isLoading={isDeleteLoading}
                />
            </Modal>
        </>
    );
};

AzureDetails.defaultProps = {
    isDeleteLoading: false,
};

AzureDetails.propTypes = {
    details: PropTypes.object.isRequired,
    deleteIntegration: PropTypes.func.isRequired,
    isDeleteLoading: PropTypes.bool,
};
