import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'Components';
import { ROUTES } from 'Constants';
import * as Styled from './styled';

export const PymakrOnline = ({ device }) => {
    const history = useHistory();

    const description = 'From here you will be able to manage and compile your code online!';
    const onOpenPymakr = () => {
        history.push(`${ROUTES.pymakr.device}/${device.token}`);
    };

    return (
        <Styled.Wrapper>
            <h1>CtrlR Online</h1>
            <p>
                {description}
            </p>
            <Button
                buttonType="filled"
                onClick={onOpenPymakr}
            >
                Open CtrlR
            </Button>
        </Styled.Wrapper>
    );
};

PymakrOnline.propTypes = {
    device: PropTypes.object.isRequired,
};
