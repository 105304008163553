import { useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { Form, Field } from 'formik';

import { TextInput, Button, RadioGroupCloud } from 'Components';
import { ALERT_TYPES } from 'Constants';

import {
    typeOptions,
    periodMultiplierOptions,
    dataUsageMultiplierOptions,
    batteryOperatorOptions,
    lastSeenInitial,
    dataUsageInitial,
    batteryInitial,
} from './config';
import * as Styled from './styled';

export const FormContent = ({ values, setValues, onClose, isEditMode, dirty, allowedAlertTypes, isLoading }) => {
    const styledTheme = useContext(ThemeContext);
    const filteredTypeOptions = useMemo(
        () => typeOptions.filter((option, index) => {
            // TODO: remove this check when all type options will be implemented
            if (index > 3) {
                return true;
            }

            return allowedAlertTypes.includes(option.value);
        }),
        [allowedAlertTypes],
    );

    useEffect(() => {
        if (dirty) {
            let additionalValues = {};

            switch (values.type) {
                case ALERT_TYPES.LAST_SEEN:
                    additionalValues = lastSeenInitial;
                    break;
                case ALERT_TYPES.DATA_USAGE:
                    additionalValues = dataUsageInitial;
                    break;
                case ALERT_TYPES.BATTERY_LEVEL:
                    additionalValues = batteryInitial;
                    break;
                default:
                    break;
            }

            const selectedTypeItem = typeOptions.find((type) => type.value === values.type);

            setValues({
                type: values.type,
                name: selectedTypeItem?.label || '',
                ...additionalValues,
            });
        }
    }, [values.type]);

    const renderLastSeenRule = () => (
        <Styled.Text>
            Alerts you when ${styledTheme.whiteLabelCompany} has NOT received any data from the device since longer than
            <Field
                name="period"
                type="number"
                min={1}
                max={999}
            />
            <Field
                name="periodMultiplier"
                as="select"
            >
                {periodMultiplierOptions.map((item) => (
                    <option
                        value={item.value}
                        key={item.value}
                    >
                        {item.label}
                    </option>
                ))}
            </Field>
        </Styled.Text>
    );

    const renderDataUsageRule = () => (
        <Styled.Text>
            Alerts you when the total amount of transferred data exceeds
            <Field
                name="dataUsage"
                type="number"
                min={1}
                max={999}
            />
            <Field
                name="dataUsageMultiplier"
                as="select"
            >
                {dataUsageMultiplierOptions.map((item) => (
                    <option
                        value={item.value}
                        key={item.value}
                    >
                        {item.label}
                    </option>
                ))}
            </Field>
            within
            <Field
                name="period"
                type="number"
                min={1}
                max={999}
            />
            <Field
                name="periodMultiplier"
                as="select"
            >
                {periodMultiplierOptions.map((item) => (
                    <option
                        value={item.value}
                        key={item.value}
                    >
                        {item.label}
                    </option>
                ))}
            </Field>
        </Styled.Text>
    );

    const renderBatteryLevelRule = () => (
        <Styled.Text>
            Alerts you when the charge of the device battery is
            <Field
                name="batteryOperator"
                as="select"
            >
                {batteryOperatorOptions.map((item) => (
                    <option
                        value={item.value}
                        key={item.value}
                    >
                        {item.label}
                    </option>
                ))}
            </Field>
            <Field
                name="batteryQuantity"
                type="number"
                step={0.001}
                min={1}
                max={100}
            />
        </Styled.Text>
    );

    const renderRule = (type) => {
        switch (type) {
            case ALERT_TYPES.LAST_SEEN:
                return renderLastSeenRule();
            case ALERT_TYPES.DATA_USAGE:
                return renderDataUsageRule();
            case ALERT_TYPES.BATTERY_LEVEL:
                return renderBatteryLevelRule();
            default:
                return null;
        }
    };

    return (
        <Form>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Name
                </Styled.ItemLabel>
                <Field
                    name="name"
                    component={TextInput}
                />
            </Styled.FormItem>
            {!isEditMode && (
                <Styled.FormItem>
                    <Styled.ItemLabel>
                        Type
                    </Styled.ItemLabel>
                    <Field
                        name="type"
                        component={RadioGroupCloud}
                        options={filteredTypeOptions}
                    />
                </Styled.FormItem>
            )}
            {!!values.type && (
                <Styled.FormItem>
                    <Styled.ItemLabel>
                        Rule
                    </Styled.ItemLabel>
                    {renderRule(values.type)}
                </Styled.FormItem>
            )}
            <Styled.ButtonsWrapper>
                <Button
                    buttonType="transparent"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button type="submit" loading={isLoading}>
                    {isEditMode ? 'Edit' : 'Create'}
                </Button>
            </Styled.ButtonsWrapper>
        </Form>
    );
};

FormContent.defaultProps = {
    isLoading: false,
};

FormContent.propTypes = {
    values: PropTypes.object.isRequired,
    setValues: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
    allowedAlertTypes: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
};
