import { Field, Form } from 'formik';
import PropTypes from 'prop-types';

import { TextInput, TextArea, Button } from 'Components';

import * as Styled from './styled';

export const FormContent = ({ isValid, onCancel }) => (
    <Form>
        <Styled.Form>
            <Styled.FormLabel>
                Name
            </Styled.FormLabel>
            <Field
                name="name"
                type="text"
                component={TextInput}
            />
        </Styled.Form>
        <Styled.Form>
            <Styled.FormLabel>
                Description
            </Styled.FormLabel>
            <Field
                name="description"
                component={TextArea}
                rows={5}
            />
        </Styled.Form>
        <Styled.ButtonsWrapper>
            <Button
                buttonType="transparent"
                onClick={onCancel}
            >
                Cancel
            </Button>
            <Button
                disabled={!isValid}
                type="submit"
            >
                Next
            </Button>
        </Styled.ButtonsWrapper>
    </Form>
);

FormContent.propTypes = {
    isValid: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
};
