import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { GET_DEVICES_QUERY, ROUTES } from 'Constants';
import {
    IconButton,
    projectDeviceColumns,
    Table,
    useLastConnection,
    Spin,
    Loader,
} from 'Components';

import { AddDevicesModal } from '../addDevicesModal';
import { UnlinkDevicesModal } from '../unlinkDevicesModal';
import { DeleteDevicesModal } from '../deleteDevicesModal';
import { NoDevicesScreen } from '../noDevicesScreen';
import { getActionButtons } from './helpers';

import * as Styled from './styled';

export const ProjectDevices = ({ project }) => {
    const history = useHistory();
    const [isAddModalOpened, setAddModalOpened] = useState(false);
    const [isUnlinkModalOpened, setUnlinkModalOpened] = useState(false);
    const [isDeleteModalOpened, setDeleteModalOpened] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [initialLoading, setInitialLoading] = useState(true);

    const {
        data,
        refetch: refetchDevices,
        loading,
    } = useQuery(GET_DEVICES_QUERY, {
        variables: { applicationId: project._id },
    });
    const devices = useMemo(
        () => data?.getDevices.edges.map((edge) => edge.node) ?? [],
        [data],
    );
    const pagination = useMemo(() => data?.getDevices.pageInfo ?? {}, [data]);

    const { data: tableData, loading: tableLoading } = useLastConnection(devices);

    useEffect(
        () => {
            if (initialLoading && !loading && !tableLoading) {
                setInitialLoading(false);
            }
        },
        [loading, tableLoading],
    );

    const redirectToDeviceUsbAdd = () => {
        history.push(ROUTES.devices.add, { projectId: project._id });
    };

    const openAddDeviceModal = () => setAddModalOpened(true);

    const unlinkDevices = () => {
        if (!selectedIds.length) {
            return;
        }

        setUnlinkModalOpened(true);
    };

    const onRowClick = (record) => {
        const { main, deviceDetails } = ROUTES.devices;
        history.push(`${main}/${record.token}${deviceDetails.dashboard}`);
    };

    const actionButtons = getActionButtons({
        addViaUsb: redirectToDeviceUsbAdd,
        addExisting: openAddDeviceModal,
        unlink: unlinkDevices,
    });

    const onPaginationChange = (cursors) => {
        refetchDevices({ paging: cursors });
    };

    const openModalRender = (
        <AddDevicesModal
            isOpen={isAddModalOpened}
            handleClose={() => setAddModalOpened(false)}
            projectId={project._id}
        />
    );

    let content = null;

    switch (true) {
        case initialLoading:
            content = <Loader />;
            break;
        case !devices.length:
            content = (
                <>
                    <NoDevicesScreen
                        openAddDeviceModal={openAddDeviceModal}
                        redirectToDeviceUsbAdd={redirectToDeviceUsbAdd}
                    />
                    {openModalRender}
                </>
            );
            break;
        case !!devices.length:
            content = (
                <Styled.TableWrapper data-cy="project-devices-table">
                    <Styled.TableControls>
                        {actionButtons.map((button) => (
                            <IconButton
                                onClick={button.onClick}
                                Icon={button.icon}
                                key={button.label}
                                cyData={button.cyData}
                            >
                                {button.label}
                            </IconButton>
                        ))}
                    </Styled.TableControls>
                    <Spin spinning={loading || tableLoading}>
                        <Table
                            columns={projectDeviceColumns}
                            data={tableData}
                            onSelect={setSelectedIds}
                            pagination={pagination}
                            onPaginationChange={onPaginationChange}
                            onRowClick={onRowClick}
                        />
                    </Spin>
                    {openModalRender}
                    <UnlinkDevicesModal
                        isOpen={isUnlinkModalOpened}
                        handleClose={() => setUnlinkModalOpened(false)}
                        selectedIds={selectedIds}
                    />
                    <DeleteDevicesModal
                        isOpen={isDeleteModalOpened}
                        handleClose={() => setDeleteModalOpened(false)}
                        selectedIds={selectedIds}
                    />
                </Styled.TableWrapper>
            );
            break;
        default:
            break;
    }

    return (
        <Styled.Wrapper data-cy="project-devices-page-wrapper">
            {content}
        </Styled.Wrapper>
    );
};

ProjectDevices.propTypes = {
    project: PropTypes.object.isRequired,
};
