import PropTypes from 'prop-types';

import { Table, Spin } from 'Components';

import { SimCardWidgetBox } from '../simCardWidgetBox';
import { SimCardEmptyWidget } from '../simCardEmptyWidget';

import * as Styled from './styled';

export const SimCardLocation = ({ history }) => {
    const { columns, items, isLoading } = history;

    return (
        <SimCardWidgetBox title="Sim Location">
            <Styled.Wrapper>
                {!items.length && !isLoading && <SimCardEmptyWidget title="No History available" />}
                 {(!!items.length || isLoading) && (
                    <Spin spinning={isLoading}>
                        <Table columns={columns} data={items} maxBodyHeight={300} />
                    </Spin>
                )}
            </Styled.Wrapper>
        </SimCardWidgetBox>
    );
};

SimCardLocation.propTypes = {
    history: PropTypes.shape({
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                dataIndex: PropTypes.string.isRequired,
            }),
        ).isRequired,
        items: PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        ),
        isLoading: PropTypes.bool,
    }).isRequired,
};
