import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { Breadcrumbs, PageHeader, ProjectAddNetworksStep } from 'Components';
import { showToastError, showToastSuccess, generateWidgetSpace } from 'Utils';
import {
    BREADCRUMBS_LABELS,
    GET_APPLICATIONS,
    CREATE_APPLICATION_MUTATION,
    CREATE_APPLICATION_WIDGET,
    CREATE_APPLICATION_CHART,
    CREATE_DEFAULT_PROJECT_CHARTS,
    ROUTES,
} from 'Constants';

import { VectorSelection, WidgetsSelection } from './components';

export const CreateFastIotProject = () => {
    const history = useHistory();

    const [formValues, setFormValues] = useState({});
    const [newApplicationId, setNewApplicationId] = useState(null);
    const [step, setStep] = useState(1);

    const { data: projectsData } = useQuery(GET_APPLICATIONS);
    const projects = projectsData?.getApplications ?? [];

    const [createProject, { loading: createProjectLoading }] = useMutation(
        CREATE_APPLICATION_MUTATION,
        { refetchQueries: [GET_APPLICATIONS] },
    );
    const [createDefaultApplicationCharts, { loading: defaultChartsLoading }] = useMutation(CREATE_DEFAULT_PROJECT_CHARTS);
    const [createWidget, { loading: widgetLoading }] = useMutation(CREATE_APPLICATION_WIDGET);
    const [createChart, { loading: chartLoading }] = useMutation(CREATE_APPLICATION_CHART);

    const redirectToPrevPage = () => history.goBack();

    const stepBack = () => {
        setStep(step - 1);
    };
    const stepForward = (values) => {
        setFormValues({
            ...formValues,
            ...values,
        });
        setStep(step + 1);
    };

    const onProjectCreate = (values) => {
        const { vector, ...restValues } = values;

        const newProjectData = {
            ...restValues,
        };

        try {
            createProject({
                variables: { applicationInput: newProjectData },
                update: (_, { data: { createApplication } }) => {
                    setNewApplicationId(createApplication._id);
                    setStep(step + 1);
                },
            });
        } catch (error) {
            showToastError(error.message);
        }
    };

    const createWidgetFlow = async (charts, widget, widgetDimensions) => {
        const { name, settings, type } = widget;

        const widgetSpecificData = generateWidgetSpace(charts, type, widgetDimensions);
        const widgetCreated = await createWidget({
            variables: { widget: { ...widgetSpecificData } },
        });
        const widgetId = widgetCreated?.data.createApplicationWidget._id;
        return createChart({
            variables: {
                chartInput: {
                    name,
                    settings,
                    applicationId: newApplicationId,
                    type,
                    widget: widgetId,
                },
            },
        });
    };

    const redirectToProjects = () => {
        showToastSuccess('Fast IoT project was successfully created');
        history.push(ROUTES.projects.main);
    };

    const onWidgetsCreate = async (values) => {
        try {
            const widgetsArray = Object.keys(values).map((widgetType) => ({
                ...values[widgetType],
                type: widgetType,
            }));

            const chartsResponse = await createDefaultApplicationCharts({ variables: { applicationId: newApplicationId } });
            const charts = chartsResponse.data?.createDefaultApplicationCharts;

            for (let i = 0; i < widgetsArray.length; i++) {
                const { width, height, ...widgetData } = widgetsArray[i];
                // eslint-disable-next-line no-await-in-loop
                const newChartResponse = await createWidgetFlow(charts, widgetData, { width, height });
                charts.push(newChartResponse.data?.createApplicationChart);
            }

            redirectToProjects();
        } catch (err) {
            showToastError('Something went wrong during widget creation');
        }
    };

    let content = null;

    switch (step) {
        case 2:
            content = (
                <ProjectAddNetworksStep
                    nextStep={onProjectCreate}
                    prevStep={stepBack}
                    formValues={formValues}
                    submitText="Next"
                    isLoading={createProjectLoading}
                />
            );
            break;

        case 3:
            content = (
                <WidgetsSelection
                    vector={formValues.vector}
                    skipStep={redirectToProjects}
                    nextStep={onWidgetsCreate}
                    loading={defaultChartsLoading || widgetLoading || chartLoading}
                />
            );
            break;

        case 1:
        default:
            content = (
                <VectorSelection
                    formValues={formValues}
                    nextStep={stepForward}
                    prevStep={redirectToPrevPage}
                    projects={projects}
                />
            );
            break;
    }

    return (
        <>
            <Breadcrumbs labels={BREADCRUMBS_LABELS.projectFastIot} />
            <PageHeader
                title="Fast IoT Project"
                step={step}
                maxStep={3}
                stepper
            />
            {content}
        </>
    );
};
