export const BREADCRUMBS_LABELS = {
    devices: {
        devices: 'All Devices',
        add: 'Add Device via USB',
        addSignal: 'Create Signal',
    },
    createSignal: {
        devices: 'All Devices',
        add: 'Create Signal',
    },
    projects: {
        projects: 'Projects',
        create: 'Create project in MicroPython',
    },
    projectFastIot: {
        projects: 'Projects',
        'create-fast-iot': 'Create Fast IoT project',
    },
    createWidget: {
        projects: 'All Projects',
        widgets: 'Widgets',
    },
    projectDetails: {
        projects: 'All Projects',
    },
    createProjectRelease: {
        projects: 'All Projects',
        create: 'Create release',
    },
    integrationDetails: {
        integrations: 'All integrations',
    },
    awsIntegration: {
        integrations: 'All integrations',
        create: 'New integration',
        aws: 'AWS',
    },
    webhookIntegration: {
        integrations: 'All integrations',
        create: 'New integration',
        webhook: 'New Webhook Integration',
    },
    azureIntegration: {
        integrations: 'All integrations',
        create: 'New integration',
        azure: 'New Azure integration',
    },
    machineLearning: {
        'machine-learning': 'My Models',
        add: 'New Model',
    },
    simCards: {
        'sim-cards': 'Sim Cards',
    },
};
