import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

import { ROUTES } from 'Constants';

import * as Styled from './styled';

export const Sigfox = ({ contractType }) => {
    const styledTheme = useContext(ThemeContext);
    const text = contractType === 'devKit'
        ? `This application will added as a Sigfox DevKit to ${styledTheme.whiteLabelCompany}. You can change this in `
        : `This application will be added using Custom Contract Sigfox account to ${styledTheme.whiteLabelCompany}.
You can change this in `;

    return (
        <>
            {text}
            <Styled.Link to={ROUTES.settings.network.sigfox}>
                &nbsp;Sigfox API settings
            </Styled.Link>.
        </>
    );
};

Sigfox.propTypes = {
    contractType: PropTypes.string.isRequired,
};
