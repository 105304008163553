import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

import { TIME_OPTIONS_LABELED, ALL_DEVICES } from 'Constants';

import { SelectWithPopup } from '../../selectWithPopup';
import { Map as ReactGoogleMap } from '../../map';
import { EmptyWidget } from '../emptyWidget';
import { NotValidWarning } from '../notValidWarning';

import * as Styled from './styled';
import * as StyledGeneral from '../styled';

export const MapHistoryWidget = ({
    data,
    updateTimePeriod,
    deviceOptions,
    updateSelectedDevice,
    title,
    initialTimeOption,
    isEmpty,
    isSomeDataBroken,
    isDataBroken,
}) => {
    const standardInitialTimeOption = TIME_OPTIONS_LABELED.find((item) => item.value === initialTimeOption);
    const [createdAtSubtraction, setCreatedAtSubtraction] = useState(standardInitialTimeOption);
    const styledTheme = useContext(ThemeContext);
    const color = styledTheme.colors.error;

    const isMapShown = !!data.length && !isEmpty && !isDataBroken;

    const selectOnChange = ({ value }) => {
        updateTimePeriod(value);
        setCreatedAtSubtraction(value);
    };

    return (
        <>
            <Styled.MapHeader>
                <StyledGeneral.ChartTitle>
                    {title}
                    {isSomeDataBroken && !isDataBroken && <NotValidWarning />}
                </StyledGeneral.ChartTitle>
                <Styled.MapHistoryOptions>
                    {!!deviceOptions?.length && (
                        <SelectWithPopup
                            initValue={ALL_DEVICES}
                            values={deviceOptions}
                            handleSelect={updateSelectedDevice}
                        />
                    )}
                    <Styled.MapMarginEnd>
                        <SelectWithPopup
                            initValue={createdAtSubtraction}
                            values={TIME_OPTIONS_LABELED}
                            handleSelect={selectOnChange}
                        />
                    </Styled.MapMarginEnd>
                </Styled.MapHistoryOptions>
            </Styled.MapHeader>
            <Styled.MapHistoryContainer className="mapContainer extended heightExtended noMargin">
                <Styled.MapHistorySelectWrapper />
                {isMapShown && (
                    <ReactGoogleMap locations={data} colorOfDefaultMarker={color} />
                )}
                {!isMapShown && <EmptyWidget isDataBroken={isDataBroken} />}
            </Styled.MapHistoryContainer>
        </>
    );
};

MapHistoryWidget.propTypes = {
    data: PropTypes.array.isRequired,
    updateTimePeriod: PropTypes.func.isRequired,
    deviceOptions: PropTypes.array,
    updateSelectedDevice: PropTypes.func,
    title: PropTypes.string.isRequired,
    initialTimeOption: PropTypes.number.isRequired,
    isEmpty: PropTypes.bool,
    isSomeDataBroken: PropTypes.bool,
    isDataBroken: PropTypes.bool,
};

MapHistoryWidget.defaultProps = {
    deviceOptions: [],
    updateSelectedDevice: () => { },
    isEmpty: false,
    isSomeDataBroken: false,
    isDataBroken: false,
};
