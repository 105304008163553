import { useState } from 'react';
import PropTypes from 'prop-types';

import { Alert, Checkbox, BaseFileUploader, Button } from 'Components';
import { ZIP_EXTENSIONS } from 'Constants';

import * as Styled from './styled';

const testReleaseFileLink = process.env.REACT_APP_RELEASE_FILE;

export const ProjectCode = ({ prevStep, nextStep, formValues }) => {
    const [isBase64, setIsBase64] = useState(!!formValues.file);
    const [shouldUpload, setShouldUpload] = useState(!!formValues.file);
    const [selectedFile, setSelectedFile] = useState(formValues.file);

    const handleCheckbox = (event) => {
        const checked = event.target.checked;
        setShouldUpload(checked);
    };

    const fileOnChange = (file) => {
        setSelectedFile(file);
        setIsBase64(false);
    };

    const onNext = () => {
        const values = { ...formValues };

        delete values.file;

        if (shouldUpload) {
            values.file = selectedFile;
            values.isBase64 = isBase64;
        }

        nextStep(values);
    };

    const alertMessage = (
        <span>
            You can try out a
            <Styled.Link
                href={testReleaseFileLink}
                target="_blank"
                rel="noopener noreferrer"
            >
                simple code
            </Styled.Link>
            from Document.
        </span>
    );

    return (
        <>
            <Styled.Title>
                Firmware version
            </Styled.Title>
            <Styled.NotificationWrapper>
                You can change a firmware version you want to deploy in this release or keep it without changes.
            </Styled.NotificationWrapper>
            <Alert
                type="info"
                message={alertMessage}
            />
            <Styled.FormWrapper>
                <Styled.FormItem>
                    <Checkbox
                        title="Upload the code"
                        checked={shouldUpload}
                        handleCheckbox={handleCheckbox}
                    />
                </Styled.FormItem>
                <Styled.UploaderWrapper>
                    <Styled.ItemLabel>
                        MicroPython code
                    </Styled.ItemLabel>
                    <BaseFileUploader
                        pymakrUpload={selectedFile && isBase64}
                        expectedExtensions={ZIP_EXTENSIONS.join(', ')}
                        maxSize={4194304}
                        defaultName="pymakr.zip"
                        disabled={!shouldUpload}
                        onChange={fileOnChange}
                    />
                </Styled.UploaderWrapper>
            </Styled.FormWrapper>
            <Styled.ButtonsWrapper>
                <Button
                    buttonType="transparent"
                    onClick={prevStep}
                >
                    Back
                </Button>
                <Button
                    onClick={onNext}
                    disabled={shouldUpload && !selectedFile}
                >
                    Next
                </Button>
            </Styled.ButtonsWrapper>
        </>
    );
};

ProjectCode.propTypes = {
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
};
