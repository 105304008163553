import { ReactComponent as USB } from 'Assets/icons/usb-top.svg';
import { ROUTES } from 'Constants';

import { Tile } from '../../buttons';
import * as Styled from './styled';

export const DevicesStarterPage = () => (
        <Styled.Tiles data-cy="device-starter-page-wrapper">
            <Tile
                icon={<USB />}
                title="Via USB"
                links={[ROUTES.devices.add]}
                cyData="add-device-button-empty"
                isNew
            />
        </Styled.Tiles>
    );
