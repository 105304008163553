import { ROUTES, LAYOUTS } from 'Constants';
import {
    Home,
    AllDevices,
    DeviceDetails,
    DeviceAddingModule,
    SignalDetails,
    SettingsModule,
    AllProjects,
    CreateProject,
    CreateFastIotProject,
    CreateWidget,
    CreateDeviceWidget,
    CreateProjectRelease,
    ProjectDetails,
    Account,
    Support,
    Pymakr,
    CubeMX,
    BlankProject,
    PymakrGitHub,
    PymakrGitPreprocessor,
    PymakrProject,
    PymakrZipFile,
    PymakrZipPreprocessor,
    PymakrDevice,
    ReleaseDetails,
    FirmwareUpdater,
    AllModels,
    CreateModel,
    ModelDetails,
    SimCardDetails,
    LoginPage,
    RegistrationPage,
    ForgotPassword,
    ResetPassword,
    ConfirmEmail,
} from 'Pages';

export const routesConfig = [
    {
        path: ROUTES.auth.login,
        component: LoginPage,
        layout: LAYOUTS.AUTH,
    },
    {
        path: ROUTES.auth.register,
        component: RegistrationPage,
        layout: LAYOUTS.AUTH,
    },
    {
        path: ROUTES.auth.forgotPassword,
        component: ForgotPassword,
        layout: LAYOUTS.AUTH,
    },
    {
        path: ROUTES.auth.resetPassword,
        component: ResetPassword,
        layout: LAYOUTS.AUTH,
    },
    {
        path: ROUTES.auth.confirmEmail,
        component: ConfirmEmail,
        layout: LAYOUTS.AUTH,
    },
    {
        path: ROUTES.main,
        component: Home,
        layout: LAYOUTS.MAIN,
        exact: true,
    },
    {
        path: ROUTES.projects.createWidget.main,
        component: CreateWidget,
        layout: LAYOUTS.MAIN,
    },
    // devices
    {
        path: ROUTES.devices.main,
        component: AllDevices,
        layout: LAYOUTS.MAIN,
        exact: true,
    },
    {
        path: ROUTES.devices.add,
        component: DeviceAddingModule,
        layout: LAYOUTS.MAIN,
    },
    {
        path: ROUTES.devices.firmware,
        component: FirmwareUpdater,
        layout: LAYOUTS.MAIN,
    },
    {
        path: ROUTES.devices.signalDetails.main,
        component: SignalDetails,
        layout: LAYOUTS.MAIN,
    },
    {
        path: ROUTES.devices.createWidget.main,
        component: CreateDeviceWidget,
        layout: LAYOUTS.MAIN,
    },
    {
        path: ROUTES.devices.deviceDetails.main,
        component: DeviceDetails,
        layout: LAYOUTS.MAIN,
    },
    // projects
    {
        path: ROUTES.projects.main,
        component: AllProjects,
        layout: LAYOUTS.MAIN,
        exact: true,
    },
    {
        path: ROUTES.projects.create,
        component: CreateProject,
        layout: LAYOUTS.MAIN,
    },
    {
        path: ROUTES.projects.createFastIot,
        component: CreateFastIotProject,
        layout: LAYOUTS.MAIN,
    },
    {
        path: `${ROUTES.projects.projectDetails.main}${ROUTES.projects.projectDetails.releaseCreate}`,
        component: CreateProjectRelease,
        layout: LAYOUTS.MAIN,
        exact: true,
    },
    {
        path: `${ROUTES.projects.projectDetails.main}${ROUTES.projects.projectDetails.releaseDetails}`,
        component: ReleaseDetails,
        layout: LAYOUTS.MAIN,
        exact: true,
    },
    {
        path: ROUTES.projects.projectDetails.main,
        component: ProjectDetails,
        layout: LAYOUTS.MAIN,
    },
    // settings
    {
        path: `${ROUTES.settings.simCards.details}/:iccid`,
        component: SimCardDetails,
        layout: LAYOUTS.MAIN,
    },
    {
        path: ROUTES.settings.main,
        component: SettingsModule,
        layout: LAYOUTS.MAIN,
    },
    {
        path: ROUTES.accountSection.main,
        component: Account,
        layout: LAYOUTS.MAIN,
    },
    // pymakr
    {
        path: ROUTES.pymakr.main,
        component: Pymakr,
        layout: LAYOUTS.MAIN,
        exact: true,
    },
    {
        path: ROUTES.pymakr.cubeMX,
        component: CubeMX,
        layout: LAYOUTS.MAIN,
    },
    {
        path: `${ROUTES.pymakr.blankProject}/:language`,
        component: BlankProject,
        layout: LAYOUTS.PYMAKR,
    },
    {
        path: ROUTES.pymakr.github,
        component: PymakrGitHub,
        layout: LAYOUTS.MAIN,
        exact: true,
    },
    {
        path: `${ROUTES.pymakr.github}/:repoName`,
        component: PymakrGitPreprocessor,
        layout: LAYOUTS.PYMAKR,
    },
    {
        path: ROUTES.pymakr.project,
        component: PymakrProject,
        layout: LAYOUTS.MAIN,
        exact: true,
    },
    {
        path: `${ROUTES.pymakr.project}/:appId/release/:relId`,
        component: PymakrProject,
        layout: LAYOUTS.PYMAKR,
    },
    {
        path: `${ROUTES.pymakr.device}/:deviceToken`,
        component: PymakrDevice,
        layout: LAYOUTS.PYMAKR,
    },
    {
        path: ROUTES.pymakr.zipFile,
        component: PymakrZipFile,
        layout: LAYOUTS.MAIN,
        exact: true,
    },
    {
        path: `${ROUTES.pymakr.zipFile}/:zipName`,
        component: PymakrZipPreprocessor,
        layout: LAYOUTS.PYMAKR,
    },
    // machine learning
    {
        path: ROUTES.machineLearning.main,
        component: AllModels,
        layout: LAYOUTS.MAIN,
        exact: true,
    },
    {
        path: ROUTES.machineLearning.createModel,
        component: CreateModel,
        layout: LAYOUTS.MAIN,
    },
    {
        path: ROUTES.machineLearning.modelDetails.modelId,
        component: ModelDetails,
        layout: LAYOUTS.MAIN,
    },
    // support
    {
        path: ROUTES.support,
        component: Support,
        layout: LAYOUTS.MAIN,
        exact: true,
    },
];
