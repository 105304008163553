import { useState, useRef, useEffect, useContext } from 'react';
import { useQuery, useApolloClient, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

import {
    GET_WEBHOOK_QUERY,
    SEND_WEBHOOK_TEST_MESSAGE,
    GET_USER_PROFILE_QUERY,
    GET_ALL_DEVICES_QUERY,
    GET_WEBHOOK_BY_NAME,
    SAVE_WEBHOOK_MUTATION,
    GET_INTEGRATION_BY_ID_QUERY,
} from 'Constants';
import {
    showToastError,
    showToastSuccess,
    transformWebhookFormToRequest,
    transformWebhookToForm,
} from 'Utils';
import { Button, Modal, DeleteModal, WebhookForm } from 'Components';

import { DevicesTable } from './devicesTable';
import { DetailsBlock } from './detailsBlock';

import * as Styled from './styled';

const DEFAULT_TEST_MESSAGE = 'Send test message';

export const WebhookDetails = ({ details, deleteIntegration, isDeleteLoading }) => {
    const apolloClient = useApolloClient();
    const [testLoading, setTestLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [testMessage, setTestMessage] = useState(DEFAULT_TEST_MESSAGE);
    const [deleteModalOpened, toggleDeleteModal] = useState(false);
    const [isEditionMode, setIsEditionMode] = useState(false);
    const timeoutRef = useRef(null);
    const styledTheme = useContext(ThemeContext);

    const { data: userData } = useQuery(GET_USER_PROFILE_QUERY);
    const user = userData?.getUserProfile ?? {};

    const { data: allDevicesData } = useQuery(
        GET_ALL_DEVICES_QUERY,
        { fetchPolicy: 'cache-and-network' },
    );
    const allDevices = allDevicesData?.device ?? [];

    const { data } = useQuery(
        GET_WEBHOOK_QUERY,
        {
            variables: { hookId: details.webhook.hookId },
            fetchPolicy: 'cache-and-network',
        },
    );
    const webhookDetails = data?.getWebHook || {};
    const { devices = [], webhook } = webhookDetails;

    const [saveWebhookMutation] = useMutation(
        SAVE_WEBHOOK_MUTATION,
        { refetchQueries: [GET_WEBHOOK_QUERY, GET_INTEGRATION_BY_ID_QUERY] },
    );

    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    const sendTestMessage = async () => {
        setTestLoading(true);

        try {
            const { data: { sendWebhookTestMessage } } = await apolloClient.query({
                query: SEND_WEBHOOK_TEST_MESSAGE,
                variables: { integrationId: webhook?.integrationRefId },
                fetchPolicy: 'network-only',
            });

            setTestMessage(sendWebhookTestMessage.message);

            timeoutRef.current = setTimeout(() => setTestMessage(DEFAULT_TEST_MESSAGE), 5000);
        } catch (error) {
            showToastError(error.message);
        } finally {
            setTestLoading(false);
        }
    };

    const deleteIntegrationHandler = async () => {
        try {
            await deleteIntegration();

            showToastSuccess('WebhHok integration deleted successfully');
        } catch (error) {
            showToastError(error.message);
        }
    };

    const onEdit = async (values) => {
        setIsLoading(true);
        try {
            const hookId = webhook?._id ? webhook._id : null;
            const { data: { getWebHookByName } } = await apolloClient.query({
                query: GET_WEBHOOK_BY_NAME,
                variables: {
                    name: values.webhookEvent,
                    hookId,
                },
            });

            if (getWebHookByName) {
                showToastError('It already exists a webhook event with this name');
                return;
            }

            const deviceTokens = values.devices.reduce((acc, item, index) => {
                if (item) {
                    acc.push(allDevices[index].token);
                }

                return acc;
            }, []);

            const requestVariables = transformWebhookFormToRequest(hookId, values, user.owner, deviceTokens);

            await saveWebhookMutation(
                { variables: { webhook: requestVariables, integrationId: webhook.integrationRefId } },
            );

            showToastSuccess('Integration edited successfully');

            setIsEditionMode(false);
        } catch (error) {
            showToastError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    if (isEditionMode) {
        const initialValues = transformWebhookToForm(webhook);

        return (
            <WebhookForm
                allDevices={allDevices}
                onSubmit={onEdit}
                initialValues={initialValues}
                submitText="Edit"
                onCancel={() => setIsEditionMode(false)}
                initialDevices={devices}
                isLoading={isLoading}
            />
        );
    }

    return (
        <>
            <DevicesTable devicesArr={devices} tokens={webhook?.deviceTokens} />
            {webhook && <DetailsBlock details={webhook} />}
            <Styled.ButtonsWrapper>
                <Button
                    buttonType="transparent"
                    onClick={() => setIsEditionMode(true)}
                >
                    Edit
                </Button>
                <Button onClick={() => toggleDeleteModal(true)}>
                    Delete
                </Button>
            </Styled.ButtonsWrapper>
            <Styled.BigButton
                loading={testLoading}
                onClick={sendTestMessage}
            >
                {testMessage}
            </Styled.BigButton>
            <Modal
                isOpened={deleteModalOpened}
                handleClose={() => toggleDeleteModal(false)}
            >
                <DeleteModal
                    deleteAction={deleteIntegrationHandler}
                    cancelAction={() => toggleDeleteModal(false)}
                    title="Are you sure you want to delete this Integration?"
                    description={`This integration will be deleted from ${styledTheme.whiteLabelCompany},
                        that means that the messages sent from your devices
                        wont be transmitted to your Webhook anymore.`}
                    isLoading={isDeleteLoading}
                />
            </Modal>
        </>
    );
};

WebhookDetails.defaultProps = {
    isDeleteLoading: false,
};

WebhookDetails.propTypes = {
    details: PropTypes.object.isRequired,
    deleteIntegration: PropTypes.func.isRequired,
    isDeleteLoading: PropTypes.bool,
};
