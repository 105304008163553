import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import { ROUTES } from 'Constants';
import {
    IconButton,
    Table,
    useLastConnection,
    Spin,
    Loader,
} from 'Components';
import { ReactComponent as USB } from 'Assets/icons/usb.svg';

import { columns } from './columns';
import * as Styled from './styled';

export const DeviceTable = ({
    data,
    onRowClick,
    pagination,
    paginationHandler,
    deviceLoading,
}) => {
    const history = useHistory();

    const { data: tableData, loading } = useLastConnection(data);

    const redirectTOAddDevice = () => {
        history.push(ROUTES.devices.add);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Styled.Wrapper data-cy="device-table-wrapper">
                <Styled.Header>
                    <IconButton
                        onClick={redirectTOAddDevice}
                        Icon={USB}
                        cyData="add-device-button"
                    >
                        Add via USB
                    </IconButton>
                </Styled.Header>
                <Spin spinning={deviceLoading}>
                    <Table
                        isRangeShow
                        columns={columns}
                        data={tableData}
                        onRowClick={onRowClick}
                        pagination={pagination}
                        onPaginationChange={paginationHandler}
                        initialRange={10}
                        labelOfRange="Devices on a page: "
                    />
                </Spin>
            </Styled.Wrapper>
        </>
    );
};

DeviceTable.defaultProps = {
    pagination: {},
    paginationHandler: () => {},
};

DeviceTable.propTypes = {
    onRowClick: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    pagination: PropTypes.object,
    paginationHandler: PropTypes.func,
    deviceLoading: PropTypes.bool.isRequired,
};
