import gql from 'graphql-tag';

export const GET_DEVICE_TEMPLATES = gql`
    query getDeviceTemplates {
        getDeviceTemplates {
            deviceTemplateTopics {
                name
                label
            }
            deviceTemplates {
                name
                ram
                flash
                system
                type
                topic
                imgUrl
                lang
                sensors
                selfContained
                supportedNetworks
            }
            shieldTemplates {
                name
                type
                imgUrl
                sensors
                supportedDevices
                supportedNetworks
                unsupportedNetworks
            }
        }
    }
`;

export const GET_DEVICE_TEMPLATES_FOR_FIRMWARE = gql`
    query getDeviceTemplates {
        getDeviceTemplates {
            deviceTemplates {
                name
                ram
                flash
                system
                type
                topic
                imgUrl
                lang
                selfContained
                supportedNetworks
            }
        }
    }
`;

export const UPDATE_DEVICE_NETWORK_MUTATION = gql`
    mutation updateNetworkConfigurations($device: UpdateDeviceInput!) {
        updateNetworkConfigurations(device: $device)
    }
`;

export const PROVISION_DEVICE = gql`
    mutation provisionDevice(
        $activationToken: String!
        $deviceMacAddress: String!
    ) {
        provisionDevice(
            activationToken: $activationToken
            deviceMacAddress: $deviceMacAddress
        )
    }
`;

export const GET_DEVICE_STATUS = gql`
    query getDeviceStatus($deviceToken: String!) {
        getDeviceStatus(deviceToken: $deviceToken) {
            lastSeen
        }
    }
`;

export const GET_DEVICE_INTEGRATIONS = gql`
    query getIntegrationsByDeviceTokens($tokenList: [String]) {
        getIntegrationsByDeviceTokens(tokenList: $tokenList) {
            _id
            name
            devices
            createdAt
        }
    }
`;

export const GET_DEVICE_BY_TOKEN_QUERY = gql`
    query getDeviceByToken($deviceToken: String!) {
        device(token: $deviceToken) {
            _id
            owner
            token
            description
            deviceType
            firmware {
                version
                intVersion
                fwtype
            }
            shield {
                type
                gateway {
                    loraProvider
                    loraRegion
                    active
                    gatewayId
                }
            }
            mac
            created
            ttnInfo {
                currentSetting
                loraOTAAppKey
                loraOTAAAppEUI
                loraOTAADeviceEUI
                loraABPNwkSKey
                loraABPAppSKey
                loraABPDevAddr
                region
            }
            pycomLoRaServerInfo {
                devEUI
                appSKey
                devAddr
                fNwkSIntKey
                nwkSEncKey
                sNwkSIntKey
                currentSetting
            }
            loraServer
            networks
            sigfoxInfo {
                id
                pac
            }
            wifi {
                _id
                ssid
                password
                secureType
            }
            lte {
                apn
                band
                carrier
                cid
                name
                protocol
                reset
            }
            ethernet
            sigfoxRegistrationStatus {
                jobDone
                status {
                    success
                    errors
                }
            }
            sigfoxContractType
            sigfoxDevKit {
                registrationStatus
                callbackStatus
            }
            application {
                _id
                name
            }
        }
    }
`;

export const UPDATE_DEVICE_NAME_MUTATION = gql`
    mutation updateDevice($deviceId: String!, $owner: String!, $name: String!) {
        saveDevice(
            device: { _id: $deviceId, owner: $owner, description: $name }
        ) {
            _id
            description
        }
    }
`;

export const UPDATE_DEVICE_WIFI_MUTATION = gql`
    mutation updateDevice(
        $deviceId: String!
        $wifiCredentials: WiFiCredentialsInput!
    ) {
        updateWifiCredentials(_id: $deviceId, wifi: $wifiCredentials) {
            ssid
            password
        }
    }
`;

export const GET_DEVICE_LOCATION_QUERY = gql`
    query getLocationQuery($deviceToken: String!) {
        getLocation(deviceToken: $deviceToken) {
            lat
            lng
            accuracy
            createdAt
        }
    }
`;

export const UPDATE_LOCATION_MUTATION = gql`
    mutation updateLocation($deviceToken: String!, $userName: String!) {
      updateLocation(deviceToken: $deviceToken, userName: $userName)
    }
`;

export const GET_DEVICES_STANDALONE = gql`
    query getDevicesStandalone {
        getDevicesStandalone {
            _id
            description
            firmware {
                version
            }
            networks
            deviceType
            token
        }
    }
`;

export const GET_ALL_FILTERED_DEVICES_QUERY = gql`
    query getAllDevices($filter: DeviceFilterInput) {
        getAllDevices(filter: $filter) {
            _id
            owner
            description
            token
            created
            mac
            deviceType
        }
    }
`;

export const GET_DEVICES_QUERY = gql`
    query getDevices(
        $paging: DevicePaging
        $sorting: DeviceSorting
        $applicationId: String
    ) {
        getDevices(
            paging: $paging
            sorting: $sorting
            applicationId: $applicationId
        ) {
            edges {
                node {
                    _id
                    application {
                        _id
                        name
                    }
                    networks
                    firmware {
                        version
                        intVersion
                        fwtype
                    }
                    token
                    mac
                    deviceType
                    description
                    created
                    releaseVersion
                    application {
                        _id
                        name
                    }
                }
                cursor
            }
            pageInfo {
                hasPreviousPage
                hasNextPage
                lastCursor
                firstCursor
            }
        }
    }
`;

export const DEVICES_LOCATION_SUBSCRIPTION = gql`
    subscription locationSubscription($deviceToken: String!) {
        locationSubscription(deviceToken: $deviceToken) {
            location {
                lat
                lng
                accuracy
                createdAt
            }
            status
            error {
                code
                message
            }
        }
    }
`;

export const DEVICE_ACTIVATED_SUBSCRIPTION = gql`
    subscription deviceActivated__api__($deviceToken: String!) {
        deviceActivated__api__(deviceToken: $deviceToken) {
            deviceMacAddress
            sigfoxId
            sigfoxPac
        }
    }
`;

export const USERS_DEVICE_ACTIVATED_SUBSCRIPTION = gql`
    subscription userDeviceActivated__api__($userName: String!) {
        userDeviceActivated__api__(userName: $userName) {
            deviceName
        }
    }
`;

export const GET_ALL_DEVICES_QUERY = gql`
    query getDevices {
        device {
            token
            description
            deviceType
            firmware {
                version
                intVersion
                fwtype
            }
        }
    }
`;

export const GET_DEVICES_BY_TOKEN = gql`
    query getDevicesByTokens($tokens: [String]!) {
        getDevicesByTokens(tokens: $tokens) {
            _id
            description
            firmware {
                version
            }
            networks
            deviceType
            token
        }
    }
`;

export const PUBLISH_DEVICE_NETWORKS_MUTATION = gql`
    mutation deployNetworkChanges($deviceToken: String!) {
        deployNetworkChanges(deviceToken: $deviceToken)
    }
`;

export const REGISTER_DEVICE_MUTATION = gql`
    mutation registerDevice($device: DeviceRegistration!, $saveWifi: Boolean) {
        registerDevice(device: $device, saveWifi: $saveWifi) {
            device {
                _id
                description
                owner
                token
                pycomLoRaServerInfo {
                    appSKey
                    currentSetting
                    devAddr
                    devEUI
                    fNwkSIntKey
                    nwkSEncKey
                    sNwkSIntKey
                }
                wifi {
                    ssid
                    password
                }
                ethernet
                networks
                deviceType
                shield {
                    type
                    gateway {
                        loraProvider
                        loraRegion
                        active
                    }
                }
            }
        }
    }
`;

export const DELETE_DEVICES_MUTATION = gql`
    mutation deleteDevices($idList: [String]!) {
        deleteDevices(idList: $idList)
    }
`;

export const DELETE_DEVICE_MUTATION = gql`
    mutation deleteDevice($deviceId: String!) {
        deleteDevice(_id: $deviceId) {
            _id
        }
    }
`;

export const UPDATE_APPLICATION_ID = gql`
    mutation updateApplicationId($_ids: [String]!, $applicationId: String) {
        updateApplicationId(_ids: $_ids, applicationId: $applicationId) {
            _id
            description
            application {
                _id
            }
        }
    }
`;

export const LAST_SEEN_QUERY = gql`
    query getLastSeen {
        lastSeen {
            deviceToken
            lastSeen
            userId
        }
    }
`;

export const LAST_SEEN_SUBSCRIPTION = gql`
    subscription lastSeenSubs($deviceToken: String!) {
        lastSeenUpdated(deviceToken: $deviceToken) {
            deviceToken
            lastSeen
        }
    }
`;

export const LIST_OF_DEVICES_BY_APP_ID = gql`
    query getDevicesByApplicationID($applicationId: String!) {
        getDevicesByApplicationID(applicationId: $applicationId) {
            description
            deviceType
            releaseVersion
            firmware {
                version
            }
            token
        }
    }
`;

export const GET_ACTIVATION_TOKEN_QUERY = gql`
    query getActivationToken($deviceToken: String!) {
        getActivationToken(deviceToken: $deviceToken)
    }
`;

export const SET_ACTIVATION_TOKEN = gql`
    mutation setActivationToken(
        $activationToken: String!
        $deviceToken: String!
    ) {
        setActivationToken(
            activationToken: $activationToken
            deviceToken: $deviceToken
        )
    }
`;

export const GET_DEVICE_CHARTS_QUERY = gql`
    query getDeviceCharts($deviceToken: String!) {
        getDeviceCharts(deviceToken: $deviceToken) {
            _id
            name
            owner
            deviceToken
            type
            widget {
                _id
                owner
                width
                height
                x
                y
                createdAt
            }
            settings {
                pin
                filteredDevices
                position {
                    lat
                    lng
                }
                unit
                timePeriod
                criteria
                limit
            }
            createdAt
        }
    }
`;

export const CREATE_DEVICE_WIDGET = gql`
    mutation createDeviceWidget($widget: WidgetCreateInput!) {
        createDeviceWidget(widget: $widget) {
            _id
        }
    }
`;

export const CREATE_DEVICE_CHART = gql`
    mutation createDeviceChart($chartInput: DeviceChartCreateInput!) {
        createDeviceChart(chartInput: $chartInput) {
            _id
            name
            owner
            deviceToken
            type
            widget {
                _id
                owner
                width
                height
                x
                y
                createdAt
            }
            settings {
                pin
                filteredDevices
                position {
                    lat
                    lng
                }
                unit
                timePeriod
                criteria
            }
            createdAt
        }
    }
`;

export const SAVE_DEVICE_WIDGETS_MUTATION = gql`
    mutation saveDeviceWidgets($widgets: [WidgetUpdateInput]!) {
        saveDeviceWidgets(widgets: $widgets) {
            _id
        }
    }
`;

export const DELETE_DEVICE_CHART = gql`
    mutation deleteDeviceChart($id: String!) {
        deleteDeviceChart(id: $id)
    }
`;

export const GET_DEVICE_LAST_MESSAGE = gql`
    query getDeviceLastMessage($chartId: String!) {
        getDeviceLastMessage(chartId: $chartId) {
            _id
            payload
            createdAt
        }
    }
`;

export const UPDATE_DEVICE_CHART = gql`
    mutation updateDeviceChart($chartInput: DeviceChartUpdateInput!) {
        updateDeviceChart(chartInput: $chartInput) {
            _id
        }
    }
`;

export const GET_DEVICE_ALLOWED_NETWORKS = gql`
    query getDeviceAllowedNetworks($input: DeviceAllowedNetworksInput!) {
        getDeviceAllowedNetworks(input: $input) {
            allowedNetworks
        }
    }
`;

export const MANAGE_LORA_GATEWAY = gql`
    mutation manageLoraGateway($gateway: ManageGatewayInput!) {
        manageLoraGateway(gateway: $gateway) {
            isProfileCreated
            gatewayId
        }
    }
`;

export const UPDATE_DEVICE_GATEWAY = gql`
    mutation updateGateway($deviceId: String!, $gateway: GatewayLoraInput!) {
        updateGateway(_id: $deviceId, gateway: $gateway)
    }
`;

export const TOGGLE_DEVICE_GATEWAY = gql`
    mutation toggleGateway($deviceToken: String!, $status: Boolean!) {
        toggleGateway(deviceToken: $deviceToken, status: $status)
    }
`;

export const DEPLOY_DEVICE_GATEWAY = gql`
    mutation deployGateway($deviceToken: String!) {
        deployGateway(deviceToken: $deviceToken)
    }
`;

export const REVOKE_DEVICE_CERTIFICATE = gql`
    mutation revokeCertificate($input: RevokeCertificateInput!) {
        revokeCertificate(input: $input) {
            certificate
            deviceId
            fingerprint
            owner
            publicKey
            status
        }
    }
`;

export const REVOKE_DEVICE_CERTIFICATE_LIST = gql`
    mutation revokeCertificate($input: [RevokeCertificateInput!]!) {
        revokeCertificatesList(input: $input){
            fingerprint
            deviceId
            status
            certificate
            publicKey
            validFrom
            validTo
        }
    }
`;

export const GENERATE_DEVICE_CERTIFICATE = gql`
  mutation generateCertificate($input: CreateCertificateInput!) {
    generateCertificate(input: $input) {
        certificate
        deviceId
        fingerprint
        publicKey
        status
    }
}
`;

export const CHECK_DEVICE_CERTIFICATE_VALIDITY = gql`
    query checkCertificateValidity($deviceId: String!, $fingerprint: String!) {
        checkCertificateValidity(deviceId: $deviceId, fingerprint: $fingerprint){
            isValid
        }
    }
`;

export const GET_DEVICE_CERTIFICATE_BY_ID = gql`
    query getCertificateByDeviceId($deviceId: String!){
        getCertificateByDeviceId(deviceId: $deviceId) {
            certificate
            deviceId
            fingerprint
            owner
            publicKey
            status
        }
    }
`;

export const GET_CERTIFICATE_BY_OWNER = gql`
    query GetCertificates($limit:Int!,$offset:Int!) {
        getCertificates(limit:$limit,offset:$offset) {
            certificates{
                fingerprint
                deviceId
                status
                certificate
                publicKey
                validFrom
                validTo
            }
            hasNextPage
        }
    }
`;

export const PUBLISH_DEVICE_CERTIFICATES_MUTATION = gql`
    mutation deployCertificatesChanges($deviceToken: String!) {
        deployCertificatesChanges(deviceToken: $deviceToken)
    }
`;

export const GET_VALID_CERTIFICATES_BY_DEVICE_IDS = gql`
    query GetValidCertificatesByIds($deviceIds:[String]!) {
        getDevicesCertificatesByDeviceIds(deviceIds: $deviceIds) {
            deviceId
            status
        }
    }
`;
