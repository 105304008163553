import PropTypes from 'prop-types';

import * as Styled from './styled';

export const Modal = ({ children, isOpened, width, handleClose, destroyOnClose }) => (
    <Styled.Modal
        centered
        closable={false}
        open={isOpened}
        width={width}
        footer={null}
        zIndex={2000}
        onCancel={handleClose}
        destroyOnClose={destroyOnClose}
    >
        {children}
    </Styled.Modal>
);

Modal.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    handleClose: PropTypes.func.isRequired,
    isOpened: PropTypes.bool.isRequired,
    width: PropTypes.number,
    destroyOnClose: PropTypes.bool,
};

Modal.defaultProps = {
    width: 700,
    destroyOnClose: true,
};
