import { Formik } from 'formik';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

import { initialModelConfigurationValues } from 'Constants';

import * as Styled from '../styled';

import { FormContent } from './formContent';

export const ModelConfiguration = ({
    nextStep,
    prevStep,
    formValues,
    hideTitle,
    hideBackButton,
    submitText,
    loading,
    disabledOnPristine,
}) => {
    const styledTheme = useContext(ThemeContext);
    const onSubmit = (values) => {
        nextStep({ ...formValues, ...values });
    };

    const onBack = (values) => {
        prevStep({ ...formValues, ...values });
    };

    const combinedInitialValues = {
        processingType: formValues.processingType || initialModelConfigurationValues.processingType,
        learningTechnique: formValues.learningTechnique || initialModelConfigurationValues.learningTechnique,
    };

    return (
        <>
            {!hideTitle && (
                <Styled.Title>
                    Model Configuration
                </Styled.Title>
            )}
            <Styled.NotificationWrapperTop>
                <Styled.NotificationLabel>
                    For training your model you need to collect the data samples straight from your&nbsp;
                    devices which already provisioned into ${styledTheme.whiteLabelCompany}
                </Styled.NotificationLabel>
            </Styled.NotificationWrapperTop>
            <Formik
                onSubmit={onSubmit}
                initialValues={combinedInitialValues}
                enableReinitialize
                validateOnMount
            >
                {(props) => (
                    <FormContent
                        {...props}
                        onBack={onBack}
                        hideBackButton={hideBackButton}
                        submitText={submitText}
                        loading={loading}
                        disabledOnPristine={disabledOnPristine}
                    />
                )}
            </Formik>
        </>
    );
};

ModelConfiguration.propTypes = {
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func,
    formValues: PropTypes.object.isRequired,
    hideTitle: PropTypes.bool,
    hideBackButton: PropTypes.bool,
    submitText: PropTypes.string,
    loading: PropTypes.bool,
    disabledOnPristine: PropTypes.bool,
};

ModelConfiguration.defaultProps = {
    hideTitle: false,
    prevStep: () => {},
    hideBackButton: false,
    submitText: 'Next',
    loading: false,
    disabledOnPristine: false,
};
