import styled from 'styled-components';

export const ChartTitle = styled.h3`
    color: ${({ theme }) => theme.colors.title};
    font-size: 23px;
    opacity: 0.89;
`;

export const ChartRefresh = styled.span`
    position: absolute;
    top: 15px;
    right: 24px;
    font-size: 16px;
    display: inline-block;
    color: ${({ theme }) => theme.colors.active};
    cursor: pointer;
    opacity: 0.89;
`;

export const ChartEmpty = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    border-color: ${({ theme }) => theme.colors.border};
    border-style: solid;
    border-left-width: 1px;
    border-bottom-width: 1px;

    p {
        width: 100%;
        font-size: 14px;
        font-weight: normal;
        line-height: 17px;
        text-align: center;
        color: ${({ theme }) => theme.colors.title};
    }
`;

export const WidgetContainer = styled.div`
    position: absolute;
    transition: all 150ms ease;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 6px;

    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    .tabPanel {
        height: 100%;
        padding: 30px 30px 35px 30px;
        display: flex;
        flex-flow: column;
        font-size: 16px;
        position: relative;
    }

    .mapContainer {
        height: 100%;
        padding: 0;
        display: flex;
        flex-flow: column;
        font-size: 16px;
    }

    .widgetEditionPanel {
        transition: all linear 100ms;
        background-color: rgba(240, 240, 240, 0.5);
        box-shadow: 0 0 0 0 rgba(50, 50, 50, 0.2);
        border: 2px solid lightgrey;
    }

    .widgetDummyDragButton:hover + .widgetEditionPanel {
        background-color: rgba(255, 255, 255, 1);
        border: 2px solid fade(#999999, 50%);
        box-shadow: 0 2px 5px 0 rgba(50, 50, 50, 0.2);
    }

    .widgetEditionPanel.selected {
        opacity: 0;
    }

    .widgetEditionPanel.overlapped {
        background-color: ${({ theme }) => theme.colors.error} !important;
    }

    .mapFooter {
        position: relative;

        button {
            margin: 15px auto;
        }
    }

    .googleMapContainer {
        height: 100%;
    }
`;
