import { useQuery } from '@apollo/client';
import { useContext, useState, useEffect } from 'react';
import { ThemeContext } from 'styled-components';

import { DevicesStarterPage, Tile, Loader } from 'Components';
import { GET_USER_PROFILE_QUERY, GET_INTEGRATIONS_QUERY, ROUTES } from 'Constants';
import { ReactComponent as Plus } from 'Assets/icons/plus.svg';

import * as Styled from './styled';
import { getIntegrationName, integrationIcons } from './config';

export const IntegrationsList = () => {
    const [initialLoading, setInitialLoading] = useState(true);
    const styledTheme = useContext(ThemeContext);

    const { data: userData, loading } = useQuery(
        GET_USER_PROFILE_QUERY,
        {
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first',
        },
    );
    const user = userData?.getUserProfile ?? {};

    const { data: integrationsData, loading: integrationsLoading } = useQuery(GET_INTEGRATIONS_QUERY, { fetchPolicy: 'cache-and-network' });
    const integrations = integrationsData?.getIntegrations;

    useEffect(
        () => {
            if (!integrationsLoading && !loading) {
                setInitialLoading(false);
            }
        }, [integrationsLoading, loading],
    );

    const hasDevices = user.devices > 0;
    const headerText = !hasDevices
        ?? `Start your journey with ${styledTheme.whiteLabelCompany} by provisioning
            your devices now via USB`;

    if (initialLoading) {
        return <Loader />;
    }

    const plusIconRender = (
        <Styled.PlusIconWrapper>
            <Plus />
        </Styled.PlusIconWrapper>
    );

    return (
        <Styled.Wrapper>
            {headerText && (
                <Styled.HeaderText>
                    {headerText}
                </Styled.HeaderText>
            )}
            {!hasDevices && <DevicesStarterPage />}
            {hasDevices && (
                <Styled.TilesWrapper>
                    <Styled.Tile>
                        <Tile
                            title="New integration"
                            links={[ROUTES.settings.integrations.create]}
                            icon={plusIconRender}
                        />
                    </Styled.Tile>
                    {integrations?.map((integration) => {
                        const Icon = integrationIcons[integration.serviceName];

                        return (
                            <Styled.Tile key={integration._id}>
                                <Tile
                                    title={getIntegrationName(integration)}
                                    links={[`${ROUTES.settings.integrations.main}/${integration._id}`]}
                                    icon={<Icon />}
                                    cyData={`integration-tile-${integration._id}`}
                                />
                            </Styled.Tile>
                        );
                    })}
                </Styled.TilesWrapper>
            )}
        </Styled.Wrapper>
    );
};
