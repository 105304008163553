import { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import {
    useQuery,
    useMutation,
    useLazyQuery,
    useSubscription,
} from '@apollo/client';
import { v4 } from 'uuid';

import { PageHeader } from 'Components';
import {
    openWebSocket,
    showToastError,
    sendWebSocket,
    showToastSuccess,
} from 'Utils';
import {
    GET_DEVICE_BY_TOKEN_QUERY,
    GET_ACTIVATION_TOKEN_QUERY,
    SET_ACTIVATION_TOKEN,
    PROVISION_DEVICE,
    LORA_OTAA,
    USERS_DEVICE_ACTIVATED_SUBSCRIPTION,
    SOCKET_OPERATIONS,
} from 'Constants';
import { SoftServerService, SocketService } from 'Services';
import { GlobalContext } from 'Context';

import {
    FirmwareUpdaterIntro,
    FirmwareUpdaterError,
    FirmwareConfiguration,
    FirmwareUpload,
    FirmwareSummary,
} from './components';
import { FirmwareContext } from './context';
import {
    firmwareInitialValues,
    DEVICES,
} from './config';
import {
    checkAvailablePorts,
    prepareFormatData,
    getMacId,
    createRegionList,
    checkEqualDeviceType,
} from './helpers';

const { REACT_APP_FIRMWARE_TOOL_VERSION, REACT_APP_MQTT_LOCALHOST_SERVER } =
    process.env;

const COMPLETED = '%COMPLETED%';

export const FirmwareUpdater = () => {
    const { deviceId: deviceToken } = useParams();
    const globalContext = useContext(GlobalContext);
    const session = globalContext.session;
    const history = useHistory();
    const timeoutOperation = useRef();
    const { data: dataOfDevice } = useQuery(GET_DEVICE_BY_TOKEN_QUERY, {
        variables: { deviceToken },
    });
    const device = dataOfDevice?.device?.[0] ?? {};

    const { data: activatedDevice } = useSubscription(
        USERS_DEVICE_ACTIVATED_SUBSCRIPTION,
        {
            variables: {
                userName: session.username,
            },
        },
    );

    const [generateToken] = useMutation(SET_ACTIVATION_TOKEN, {
        refetchQueries: [GET_ACTIVATION_TOKEN_QUERY],
    });

    const [registerDevice, { data: dataOfRegistrationDevice }] =
        useMutation(PROVISION_DEVICE);

    const [getActivationToken, { data: activationToken }] = useLazyQuery(
        GET_ACTIVATION_TOKEN_QUERY,
        {
            onCompleted: (data) => {
                if (isNull(data?.getActivationToken)) {
                    const generatedDeviceToken = v4();
                    generateToken({
                        variables: {
                            activationToken: generatedDeviceToken,
                            deviceToken: device?.token,
                        },
                    });
                }
            },
        },
    );

    const token = activationToken?.getActivationToken ?? {};
    const registerInfo = dataOfRegistrationDevice ?? {};

    const [step, setStep] = useState(1);
    const [isLoaded, setIsLoaded] = useState(false);
    const [firmwareData, setFirmwareData] = useState(firmwareInitialValues);
    const [socketData, setSocketData] = useState(SocketService);
    const [webSocket, setWebSocket] = useState(null);
    const [logs, setLogs] = useState([]);
    const [disabledThirdStep, setDisabledThirdStep] = useState(true);

    useEffect(() => {
        if (!isEmpty(activatedDevice)) {
            const { deviceName } = activatedDevice?.userDeviceActivated__api__;
            showToastSuccess(
                `${deviceName}  was successfully connected to firmware updater.`,
            );
        }
    }, [activatedDevice]);

    useEffect(() => {
        const error = socketData.errorMessage;
        if (error) {
            showToastError(error);
        }
    }, [socketData.errorMessage]);

    const addLogs = (message) => {
        setLogs((prevState) => {
            const newLogs = [...prevState];
            if (message !== COMPLETED) {
                const lastLog = newLogs[newLogs?.length - 1];
                if (
                    lastLog &&
                    lastLog.includes('Writing') &&
                    lastLog.includes('%') &&
                    message.includes('Writing') &&
                    message.includes('%')
                ) {
                    newLogs.pop();
                }
                newLogs.push(message);
            }
            return [...new Set(newLogs)];
        });
    };

    const cleanLogs = () => {
        setLogs([]);
    };

    const generateLink = (data) => {
        let link = null;
        if (data?.ttnInfo && data?.ttnInfo?.currentSetting) {
            const {
                currentSetting,
                loraOTAADeviceEUI,
                loraOTAAAppEUI,
                loraOTAAppKey,
                loraABPAppSKey,
                loraABPDevAddr,
                loraABPNwkSKey,
            } = data?.ttnInfo;
            if (currentSetting === LORA_OTAA) {
                link = `${currentSetting}:${loraOTAADeviceEUI}:${loraOTAAAppEUI}:${loraOTAAppKey}`;
            } else {
                link = `${currentSetting}:${loraABPDevAddr}:${loraABPNwkSKey}:${loraABPAppSKey}`;
            }
        }
        return link;
    };

    const getConfig = () => {
        const {
            firmwareVersionObject,
            userConfiguration: { loraRegion, fileSystem, port, speed },
        } = firmwareData;

        const msg = !isEmpty(firmwareData?.customFile)
            ? `Writing custom firmware into ${port} at ${speed} hz...`
            : `Writing firmware (${firmwareData.deviceType} ${firmwareVersionObject?.version}) into ${port} at ${speed} hz...`;
        addLogs(msg);

        const link = generateLink(device);
        //TODO if don't work change link to eprefs
        const { sigfoxId, sigfoxPac, sigfoxPrivateKey, sigfoxPublicKey } =
            firmwareData;

        return {
            wifi_ssid:
                device.wifi && device.wifi.ssid ? device.wifi.ssid : null,
            wifi_pwd:
                device.wifi && device.wifi.password
                    ? device.wifi.password
                    : null,
            wifi_on_boot: 1,
            lora_region: loraRegion ?? null,
            nwprefs: device.networks,
            pybytes: {
                userId: session.username,
                device_token: device.token,
                mqttServiceAddress: REACT_APP_MQTT_LOCALHOST_SERVER,
                link,
            },
            lte: {
                apn: device.lte?.apn,
                cid: device.lte?.cid,
                reset: device.lte?.reset,
                carrier: device.lte?.carrier,
                band: device.lte?.band,
                type: device.lte?.protocol,
            },
            fstype: fileSystem === 'none' ? undefined : fileSystem,
            sigfox: {
                sigfoxId,
                sigfoxPac,
                sigfoxPrivateKey,
                sigfoxPublicKey,
            },
        };
    };

    const cleanOperations = (operation) => {
        setFirmwareData((prevState) => {
            const timeOut = prevState.timeOut.filter(
                (item) => item !== operation,
            );
            return {
                ...prevState,
                timeOut,
            };
        });
    };

    const writeFirmware = async (config) => {
        const {
            firmwareVersionObject: { speed },
            userConfiguration: { effs, envs, port },
            firmwareConfig: { fileName },
            remoteConfig,
        } = firmwareData;

        await sendWebSocket(webSocket, SOCKET_OPERATIONS.WRITE_FIRMWARE, {
            config,
            port,
            filename: fileName,
            baud_rate: speed,
            erase_fs: effs,
            erase_nvs: envs,
            remote_config: remoteConfig,
        });
    };

    const handleTimeOutRequest = (
        listOfOperations,
        operation,
        time = 15000,
    ) => {
        timeoutOperation.current = setTimeout(() => {
            const executedOperation = listOfOperations.filter(
                (item) => item === operation,
            );
            if (executedOperation?.length > 0) {
                const items = operation.split('_');
                items.pop();
                const operationName = items.join(' ');
                cleanOperations(operation);
                showToastError(
                    `Time out waiting for response for ${operationName}`,
                );
            }
        }, time);
    };

    const setRegionList = (data) => {
        const [regionList, countriesList, countriesForForm] =
            createRegionList(data);
        setFirmwareData({
            ...firmwareData,
            regionList,
            countriesList,
            countriesForForm,
        });
        setIsLoaded(false);
    };

    const setRemoteConfig = (data) => {
        const {
            status,
            loramac_s: loramac,
            sigfox_id: sigfoxId,
            sigfox_pac: sigfoxPac,
            sigfox_private_key: sigfoxPrivateKey,
            sigfox_public_key: sigfoxPublicKey,
        } = data;
        if (status === 200) {
            setFirmwareData((prevState) => ({
                ...prevState,
                loramac,
                sigfoxId,
                sigfoxPac,
                sigfoxPrivateKey,
                sigfoxPublicKey,
            }));
        }
        setIsLoaded(false);
    };

    const getRemoteConfig = async (config) => {
        setIsLoaded(true);
        try {
            const timeCheck = await sendWebSocket(
                webSocket,
                SOCKET_OPERATIONS.REMOTE_CONFIG,
                { config },
            );
            setFirmwareData((prevState) => ({
                ...prevState,
                timeOut: [...prevState.timeOut, timeCheck],
            }));
            handleTimeOutRequest(firmwareData.timeOut, timeCheck);
        } catch (error) {
            showToastError(error);
        }
    };

    const checkLoraForDevice = (deviceType) => {
        let enableSelectSigfoxRegion = false;
        let enableSelectRegion = false;
        switch (deviceType) {
            case DEVICES.LOPY4:
            case DEVICES.FIPY:
                enableSelectRegion = true;
                enableSelectSigfoxRegion = true;
                break;
            case DEVICES.LOPY:
                enableSelectRegion = true;
                break;
            case DEVICES.SIPY:
                enableSelectSigfoxRegion = true;
                break;
            default:
                enableSelectRegion = false;
                enableSelectSigfoxRegion = false;
                break;
        }
        setFirmwareData((prevState) => ({
            ...prevState,
            enableSelectRegion,
            enableSelectSigfoxRegion,
        }));
    };

    const setDeviceInfo = async (data) => {
        let sigfoxObj = [{ id: 'FFFFFFFFFFFFFFFF', type: 'lpwan' }];
        const deviceTypeFormAPI = device?.deviceType;
        const { mac, region, sMac, selectedLoraRegion } =
            prepareFormatData(data);
        const macId = getMacId(mac, sMac);

        const deviceInfo = await SoftServerService.getDeviceType(mac, macId);
        let { deviceLongName, deviceType } = deviceInfo;
        const { smac, binary, remoteConfig } = deviceInfo;

        if (deviceLongName !== undefined && !isEmpty(deviceLongName)) {
            await getRemoteConfig(binary);
            sigfoxObj = smac || sMac;
            deviceType = deviceType || deviceTypeFormAPI;
        } else {
            deviceLongName = '';
            deviceType = deviceTypeFormAPI;
        }

        checkLoraForDevice(deviceType);
        if (!checkEqualDeviceType(deviceType, deviceTypeFormAPI)) {
            setFirmwareData((prevState) => ({
                ...prevState,
                deviceLongName,
                deviceType,
                mac,
                binary,
                region,
                remoteConfig,
                sigfoxObj,
                selectedLoraRegion,
                errorEqualDevice: false,
                devicesToForm: [deviceType.toUpperCase()],
            }));
        } else {
            setFirmwareData((prevState) => ({
                ...prevState,
                errorEqualDevice: true,
            }));
        }
    };

    const scanPorts = async () => {
        setIsLoaded(true);
        try {
            const timeCheck = await sendWebSocket(
                webSocket,
                SOCKET_OPERATIONS.SCAN_PORTS,
            );
            setFirmwareData((prevState) => ({
                ...prevState,
                timeOut: [...prevState.timeOut, timeCheck],
            }));
            handleTimeOutRequest(firmwareData.timeOut, timeCheck);
        } catch (error) {
            showToastError(error);
        }
    };

    const getDeviceInfo = async (port) => {
        setIsLoaded(true);
        try {
            const timeCheck = await sendWebSocket(webSocket, SOCKET_OPERATIONS.INFO, {
                port,
            });
            setFirmwareData((prevState) => ({
                ...prevState,
                timeOut: [...prevState.timeOut, timeCheck],
            }));
            handleTimeOutRequest(firmwareData.timeOut, timeCheck);
        } catch (error) {
            showToastError(error);
        }
    };

    const getRegionList = async () => {
        setIsLoaded(true);
        try {
            const timeCheck = await sendWebSocket(
                webSocket,
                SOCKET_OPERATIONS.REGION_LIST,
                { tool_version: +REACT_APP_FIRMWARE_TOOL_VERSION },
            );
            setFirmwareData((prevState) => ({
                ...prevState,
                timeOut: [...prevState.timeOut, timeCheck],
            }));
            handleTimeOutRequest(firmwareData.timeOut, timeCheck);
        } catch (error) {
            showToastError(error);
        }
    };

    const downloadFileFirmware = async (config) => {
        try {
            const timeCheck = await sendWebSocket(
                webSocket,
                SOCKET_OPERATIONS.DOWNLOAD_FIRMWARE,
                {
                    url: config.file,
                    filename: config.fileName,
                    local_file: config.local_file,
                },
            );
            setFirmwareData((prevState) => ({
                ...prevState,
                timeOut: [...prevState.timeOut, timeCheck],
            }));
            handleTimeOutRequest(firmwareData.timeOut, timeCheck);
        } catch (error) {
            showToastError(error);
        }
    };

    const flashFirmware = async (data) => {
        const { status } = data;
        if (status === 200) {
            addLogs('Download completed...');
            const allConfiguration = await getConfig();
            await writeFirmware(allConfiguration);
        }
    };

    const setPorts = (data) => {
        const ports = [];
        data.forEach((item) => {
            ports.push({
                value: item,
                label: item,
            });
        });
        checkAvailablePorts(ports);
        setFirmwareData({ ...firmwareData, ports });
        setIsLoaded(false);
    };

    const getActivationTokenFromAPI = async () => {
        try {
            getActivationToken({
                variables: {
                    deviceToken: device.token,
                },
            });
        } catch (error) {
            console.warn(error);
        }
    };

    const registerDeviceToAPI = async () => {
        const { mac: deviceMacAddress } = firmwareData;
        try {
            registerDevice({
                variables: {
                    deviceMacAddress,
                    activationToken: token,
                },
            });
        } catch (error) {
            console.warn('error: ', error);
        }
    };

    const writeFirmwareOnDevice = async (data) => {
        if (data === COMPLETED) {
            addLogs(
                `Your ${firmwareData?.deviceType} firmware has been successfully updated!`,
            );
            if (!isNull(token)) {
                addLogs('Registering device...');
                registerDeviceToAPI();
            }
        } else {
            addLogs(data);
        }
    };

    const showError = (data) => {
        if (typeof data === 'string') {
            const [, error] = data.split(':');
            showToastError(error.trim());
        } else {
            const { error } = data;
            showToastError(error);
        }
        setIsLoaded(false);
    };

    const separateOperations = (action, data) => {
        switch (action) {
            case SOCKET_OPERATIONS.SCAN_PORTS: {
                setPorts(data);
                break;
            }
            case SOCKET_OPERATIONS.INFO: {
                setDeviceInfo(data, device);
                break;
            }
            case SOCKET_OPERATIONS.REGION_LIST: {
                setRegionList(data);
                break;
            }
            case SOCKET_OPERATIONS.DOWNLOAD_FIRMWARE: {
                flashFirmware(data);
                break;
            }
            case SOCKET_OPERATIONS.REMOTE_CONFIG: {
                setRemoteConfig(data);
                break;
            }
            case SOCKET_OPERATIONS.WRITE_FIRMWARE: {
                addLogs(data);
                writeFirmwareOnDevice(data);
                break;
            }
            case SOCKET_OPERATIONS.ERROR: {
                showError(data);
                break;
            }
            default:
                return null;
        }
    };

    const handleMessage = useCallback(
        (event) => {
            const data = JSON.parse(event.data);
            const { results, action, timeoutRef } = data;
            cleanOperations(timeoutRef);
            separateOperations(action, results);
        },
        [socketData.event],
    );

    useEffect(() => {
        if (!isEmpty(socketData.event)) {
            handleMessage(socketData.event);
        }
    }, [handleMessage]);

    const openSocket = async () => {
        const ws = await openWebSocket(setSocketData, socketData);
        setWebSocket(ws);
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const goBack = () => {
        history.goBack();
    };

    const backStep = () => {
        setStep(step - 1);
    };

    const closeSocket = () => {
        webSocket?.close();
    };

    useEffect(() => {
        openSocket();
        return () => {
            closeSocket();
            clearTimeout(timeoutOperation.current);
        };
    }, []);

    useEffect(() => {
        if (!isEmpty(device)) {
            getActivationTokenFromAPI();
        }
    }, [device]);

    useEffect(() => {
        if (!isEmpty(registerInfo)) {
            addLogs('Your device has been successfully registered!');
            closeSocket();
            setDisabledThirdStep(false);
        }
    }, [registerInfo]);

    useEffect(() => {
        setFirmwareData({ ...firmwareData, logs });
    }, [logs]);

    let content;

    switch (step) {
        case 1:
        default:
            content = (
                <FirmwareUpdaterIntro nextStep={nextStep} goBack={goBack} />
            );
            break;
        case 2:
            content = (
                <FirmwareConfiguration
                    webSocket={webSocket}
                    scanPorts={scanPorts}
                    getDeviceInfo={getDeviceInfo}
                    getRegionList={getRegionList}
                    backStep={backStep}
                    nextStep={nextStep}
                    isLoaded={isLoaded}
                    setIsLoaded={setIsLoaded}
                />
            );
            break;
        case 3:
            content = (
                <FirmwareUpload
                    backStep={backStep}
                    downloadFileFirmware={downloadFileFirmware}
                    webSocket={webSocket}
                    addLogs={addLogs}
                    logs={logs}
                    cleanLogs={cleanLogs}
                    disabled={disabledThirdStep}
                    nextStep={nextStep}
                />
            );
            break;
        case 4:
            content = <FirmwareSummary />;
            break;
    }

    return (
        <>
            <PageHeader
                stepper
                step={step}
                maxStep={4}
                title="Firmware Updater"
            />
            {isEmpty(webSocket) && !socketData.isSocketOpen ? (
                <FirmwareUpdaterError actionClick={openSocket} />
            ) : (
                <FirmwareContext.Provider
                    value={{ firmwareData, setFirmwareData }}
                >
                    <div>{content}</div>
                </FirmwareContext.Provider>
            )}
        </>
    );
};
