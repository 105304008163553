import { useState, useEffect } from 'react';
import { useParams, useRouteMatch, Switch, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_APPLICATION_QUERY, ROUTES, BREADCRUMBS_LABELS } from 'Constants';
import { Breadcrumbs, Menu, RouteWrapper, Loader } from 'Components';
import { generateDeviceNetworkIcon } from 'Utils';

import { ProjectDevices, ProjectReleases, ProjectConfiguration, ProjectDashboard } from './components';

import * as Styled from './styled';

const getLinks = (basePath) => [
    { title: 'Dashboard', route: `${basePath}${ROUTES.projects.projectDetails.dashboard}` },
    { title: 'Devices', route: `${basePath}${ROUTES.projects.projectDetails.devices}` },
    { title: 'Releases', route: `${basePath}${ROUTES.projects.projectDetails.releases}` },
    { title: 'Configuration', route: `${basePath}${ROUTES.projects.projectDetails.configuration}` },
];

export const ProjectDetails = () => {
    const { id } = useParams();
    const { path, url } = useRouteMatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { data, loading } = useQuery(
        GET_APPLICATION_QUERY,
        {
            variables: { id },
            fetchPolicy: 'cache-and-network',
        },
    );
    const project = data?.getApplication ?? undefined;

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    if (initialLoading) {
        return <Loader />;
    }

    if (!project) {
        return 'There is no such project';
    }

    return (
        <>
            <Breadcrumbs
                labels={BREADCRUMBS_LABELS.projectDetails}
                entityName={project.name}
            />
            <Styled.HeaderWrapper>
                <Styled.Title>
                    {project.name}
                </Styled.Title>
                <Styled.HeaderRightBlock>
                    ProjectNetworks
                    <Styled.NetworksWrapper>
                        {project.networks.map((network) => generateDeviceNetworkIcon(network))}
                    </Styled.NetworksWrapper>
                </Styled.HeaderRightBlock>
            </Styled.HeaderWrapper>
            <Menu links={getLinks(url)}>
                <Switch>
                    <RouteWrapper
                        path={`${path}${ROUTES.projects.projectDetails.dashboard}`}
                        render={(props) => <ProjectDashboard {...props} project={project} />}
                    />
                    <RouteWrapper
                        path={`${path}${ROUTES.projects.projectDetails.devices}`}
                        render={(props) => <ProjectDevices {...props} project={project} />}
                    />
                    <RouteWrapper
                        path={`${path}${ROUTES.projects.projectDetails.releases}`}
                        render={(props) => <ProjectReleases {...props} project={project} />}
                    />
                    <RouteWrapper
                        path={`${path}${ROUTES.projects.projectDetails.configuration}`}
                        render={(props) => <ProjectConfiguration {...props} project={project} />}
                    />
                    <Redirect to={`${path}${ROUTES.projects.projectDetails.dashboard}`} />
                </Switch>
            </Menu>
        </>
    );
};
