import { useState, useEffect, useCallback } from 'react';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';

import { Notification } from 'Components';
import { GlobalStyles } from 'Styles';
import { GlobalContext } from 'Context';
import { getClient } from 'Queries';
import { Routes } from 'Routes';

import 'antd/dist/antd.css';

const { REACT_APP_STATIC_URL, REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;

export const App = () => {
    const [theme, setTheme] = useState(null);
    const [state, setState] = useState({ session: null, client: getClient() });

    const setSession = useCallback((session) => {
        const client = getClient(session);

        setState({ session, client });
    }, []);

    const downloadTheme = async () => {
        try {
            const response = await fetch(`${REACT_APP_STATIC_URL}/theme.json`);
            const themeResponse = await response.json();

            setTheme(themeResponse);
        } catch (error) {
            console.log('problems with loading theme, please check theme.json file');
        }
    };

    useEffect(() => {
        downloadTheme();
    }, []);

    if (!theme) {
        return null;
    }

    return (
        <GlobalContext.Provider
            value={{
                session: state.session,
                client: state.client,
                setSession,
            }}
        >
        <LoadScript
            id="script-loader"
            googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY}
        >
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <ApolloProvider client={state.client}>
                    <Notification />
                    <BrowserRouter>
                        <Routes />
                    </BrowserRouter>
                </ApolloProvider>
            </ThemeProvider>
        </LoadScript>

        </GlobalContext.Provider>
    );
};
