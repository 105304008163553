import { showToastError, showToastSuccess } from 'Utils';

export const checkAvailablePorts = (ports) => {
    if (ports.length > 0) {
        let message = `${ports.length} port available`;
        if (ports.length > 1) {
            message = `${ports.length} ports available`;
        }
        showToastSuccess(message);
    } else {
        showToastError('No available ports!');
    }
};

export const prepareFormatData = (data) => {
    const { mac: macAddress, lora_region: region, s_mac: sMac } = data;
    const mac = macAddress.toUpperCase().split(':').join('');
    const selectedLoraRegion = region || '';
    return { mac, region, sMac, selectedLoraRegion };
};

export const getMacId = (mac, sMac) => {
    if (mac !== undefined && mac !== null) {
        const macId = sMac.length > 0 ? sMac[0].id : 'F'.repeat(16);
        return macId;
    }
    showToastError(
        'The device has no MAC Address please contact the support team!',
    );
};

export const createRegionList = (data) => {
    const countriesList = [];
    let regionList = [];
    const countriesForForm = [];
    data.forEach((element) => {
        countriesList.push({
            country: element[0],
            region: element[1],
        });
        countriesForForm.push({
            label: element[0],
            value: `${element[0]}_${element[1]}`,
        });
        regionList.push(element[1]);
    });
    regionList = regionList.filter(
        (item, index, array) => array.indexOf(item) === index,
    );
    return [regionList, countriesList, countriesForForm];
};

export const checkEqualDeviceType = (deviceType, anotherDeviceType) => {
    if (deviceType !== anotherDeviceType) {
        showToastError(`The selected port has device ${deviceType} which is different from ${anotherDeviceType}.
            please select another port`);
    }
    return deviceType !== anotherDeviceType;
};
