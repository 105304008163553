import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { ClickBoard, GenerationToken } from 'Components';
import { GET_ACTIVATION_TOKEN_QUERY } from 'Constants';
import { showToastSuccess } from 'Utils';

import * as Styled from './styled';

const {
    REACT_APP_PYCOM_FIRMWARE_UPDATER_WINDOWS_DOWNLOAD_LINK,
    REACT_APP_PYCOM_FIRMWARE_UPDATER_LINUX_DOWNLOAD_LINK,
    REACT_APP_PYCOM_FIRMWARE_UPDATER_MAC_OS_DOWNLOAD_LINK,
} = process.env;

export const DeviceProvisioningOffline = ({ deviceToken }) => {
    const styledTheme = useContext(ThemeContext);

    const { data } = useQuery(GET_ACTIVATION_TOKEN_QUERY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            deviceToken,
        },
    });
    const token = data?.getActivationToken ?? '';

    const handleCopy = () => {
        showToastSuccess('Copied');
    };

    const descriptionText = `Provision the device to the
        ${styledTheme.whiteLabelCompany} platform using offline
        Firmware Updater`;

    return (
        <Styled.Content>
            <Styled.Header>
                <Styled.Title>
                    Provisioning via Firmware Updater Offline
                </Styled.Title>
                <Styled.Description>
                    {descriptionText}
                </Styled.Description>
            </Styled.Header>
            <Styled.List>
                <Styled.ListItem>
                    <Styled.Text>
                        <Styled.ListNumber>
                            1.
                        </Styled.ListNumber>
                        <Styled.ListDescription>
                            <span>Install firmware updater:</span>
                            <Styled.Link
                                href={
                                    REACT_APP_PYCOM_FIRMWARE_UPDATER_WINDOWS_DOWNLOAD_LINK
                                }
                            >
                                Windows version
                            </Styled.Link>
                            |
                            <Styled.Link
                                href={
                                    REACT_APP_PYCOM_FIRMWARE_UPDATER_MAC_OS_DOWNLOAD_LINK
                                }
                            >
                                MacOS version
                            </Styled.Link>
                            |
                            <Styled.Link
                                target="_blank"
                                rel="noopener noreferrer"
                                href={
                                    REACT_APP_PYCOM_FIRMWARE_UPDATER_LINUX_DOWNLOAD_LINK
                                }
                            >
                                Linux version
                            </Styled.Link>
                        </Styled.ListDescription>
                    </Styled.Text>
                </Styled.ListItem>
                <Styled.ListItem>
                    <Styled.Text>
                        <Styled.ListNumber>
                            2.
                        </Styled.ListNumber>
                        <Styled.ListDescription>
                            During firmware update paste this Activation Token
                            to the updater:
                        </Styled.ListDescription>
                    </Styled.Text>
                </Styled.ListItem>
                {!token?.length ? (
                    <GenerationToken deviceToken={deviceToken} />
                ) : (
                    <ClickBoard text={token} handleCopy={handleCopy} />
                )}
            </Styled.List>
        </Styled.Content>
    );
};

DeviceProvisioningOffline.propTypes = {
    deviceToken: PropTypes.string.isRequired,
};
