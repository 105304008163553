import { useState } from 'react';
import PropTypes from 'prop-types';

import { VECTORS, VECTOR_LABELS } from 'Constants';
import { Button } from 'Components';

import { ProjectNameModal } from '../projectNameModal';

import * as Styled from './styled';

const vectorOptions = Object.values(VECTORS).map((value) => ({
    name: value,
    label: VECTOR_LABELS[value].label,
    icon: VECTOR_LABELS[value].icon,
}));

export const VectorSelection = ({ prevStep, nextStep, projects, formValues }) => {
    const [isNameModalOpen, setIsNameModalOpen] = useState(false);
    const [values, setValues] = useState({
        name: formValues.name,
        vector: formValues.vector,
    });

    const onVectorClick = (vector) => {
        setValues({ ...values, vector });
        setIsNameModalOpen(true);
    };

    const nameOnSubmit = ({ name, description }) => {
        nextStep({ ...values, name, description });
    };

    const renderVector = (item) => {
        const Icon = item.icon;

        return (
            <Styled.ListItem
                key={item.name}
                onClick={() => onVectorClick(item.name)}
            >
                <Icon />
                {item.label}
            </Styled.ListItem>
        );
    };

    return (
        <>
            <Styled.Title>
                Please select your industry below
            </Styled.Title>
            <Styled.ListWrapper>
                {vectorOptions.map(renderVector)}
            </Styled.ListWrapper>
            <Styled.ButtonsWrapper>
                <Button
                    buttonType="transparent"
                    onClick={prevStep}
                >
                    Back
                </Button>
            </Styled.ButtonsWrapper>
            <ProjectNameModal
                isOpen={isNameModalOpen}
                onClose={() => setIsNameModalOpen(false)}
                projects={projects}
                onSubmit={nameOnSubmit}
                initialValues={{ name: values.name }}
            />
        </>
    );
};

VectorSelection.propTypes = {
    prevStep: PropTypes.func.isRequired,
    projects: PropTypes.array.isRequired,
    nextStep: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
};
