import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { ThemeContext } from 'styled-components';
import { FIRMWARE_QUERY } from 'Constants';
import { Collapsible, CollapsibleTag } from 'Components';
import {
    DeviceSettingsForm,
    DeviceNetworksForm,
    DeviceDeleteForm,
    DeviceFirmware,
    DeviceExpansionBoard,
} from './components';
import {
    FIRMWARE_STATUS,
    getDeviceFirmwareStatus,
} from './config';

import * as Styled from './styled';

export const DeviceConfiguration = ({ device }) => {
    const styledTheme = useContext(ThemeContext);
    const { data: firmwareQueryData, loading: firmwareLoading } = useQuery(FIRMWARE_QUERY);
    const firmwareLatestVersion = firmwareQueryData?.getLatestFirmware;
    const [firmwareStatus, setFirmwareStatus] = useState({ text: '', type: '' });
    const [currentFirmwareVersion, setCurrentFirmwareVersion] = useState(device.firmware);

    const updateFirmwareStatus = (status) => {
        let type = '';
        switch (status) {
            case FIRMWARE_STATUS.NO_INFORMATION:
                type = 'warning';
                break;
            case FIRMWARE_STATUS.UPDATING:
                type = 'info';
                break;
            case FIRMWARE_STATUS.UP_TO_DATE:
                type = 'success';
                break;
            case FIRMWARE_STATUS.UPDATE_AVAILABLE:
                type = 'error';
                break;
            default:
                break;
        }

        if (type) {
            setFirmwareStatus({ text: status, type });
        }
    };

    useEffect(() => {
        updateFirmwareStatus(getDeviceFirmwareStatus(currentFirmwareVersion, firmwareLatestVersion));
    }, [device, firmwareLatestVersion]);

    if (firmwareLoading) {
        return null;
    }

    const updateCurrentFirmwareVersion = (version) => {
        setCurrentFirmwareVersion(version);
    };

    const collapsibleLabels = [
        {
            title: 'Device settings',
            description: 'Manage your device name on the one that suitable for you most',
        },
        {
            title: 'Expansion Board',
            description: 'By changing the expansion board you will change the functionality of your device depends on the sensors and '
                + 'trackers that are placed on the new expansion board.',
        },
        {
            title: 'Networks',
            description: 'Setup and change your connectivity settings',
        },
        {
            title: (
                <span>OTA firmware {currentFirmwareVersion.version}
                    <CollapsibleTag
                        title={firmwareStatus.text}
                        type={firmwareStatus.type}
                    />
                </span>
            ),
            description: 'Update your firmware Over the Air',
        },
        {
            title: 'Remove device',
            description: `Here you can delete your device from your ${styledTheme.whiteLabelCompany} account`,
        },
    ];

    return (
        <Styled.Wrapper
            data-cy="device-configuration-collapsible-items"
        >
            <Collapsible labels={collapsibleLabels} forceRenderIndexes={[2]}>
                <DeviceSettingsForm device={device} />
                <DeviceExpansionBoard device={device} />
                <DeviceNetworksForm device={device} />
                <DeviceFirmware
                    device={device}
                    updateFirmwareStatus={updateFirmwareStatus}
                    updateCurrentFirmwareVersion={updateCurrentFirmwareVersion}
                    currentFirmwareVersion={currentFirmwareVersion}
                />
                <DeviceDeleteForm device={device} />
            </Collapsible>
        </Styled.Wrapper>
    );
};

DeviceConfiguration.propTypes = {
    device: PropTypes.object,
};

DeviceConfiguration.defaultProps = {
    device: {},
};
