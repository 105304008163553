import moment from 'moment';

import { getStringifiedPayload, getNetworkIcon } from 'Utils';
import { Dropdown } from 'Components';

import * as Styled from '../../styled';

export const getColumns = ({ editSignal }) => {
    const dropdownActions = [
        {
            executedFunction: editSignal,
            title: 'Edit',
            cyData: 'signal-edit',
        },
    ];

    return [
        {
            title: 'Network',
            dataIndex: 'lastMessage',
            render: (value) => {
                const Icon = getNetworkIcon(value?.networkType);
                return (
                    <Styled.IconWrapper>
                        <Icon />
                    </Styled.IconWrapper>
                );
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Last received',
            dataIndex: 'lastMessage',
            render: (value) => {
                if (!value?.createdAt) {
                    return '-';
                }

                const time = moment(new Date(value?.createdAt));

                return time.fromNow();
            },
        },
        {
            title: 'Last message',
            dataIndex: 'lastMessage',
            render: (value, pin) => {
                const valueContent = value?.payload ? getStringifiedPayload(value.payload) : '';

                if (!valueContent) {
                    return '-';
                }

                return `${valueContent} ${pin.unit}`;
            },
        },
        {
            title: 'Signal',
            dataIndex: 'pin',
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            width: '15%',
            render: (id) => (
                <Dropdown
                    actions={dropdownActions}
                    id={id}
                    cyData="signal-dropdown-button"
                />
            ),
        },
    ];
};
