import { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';

import {
    GET_INTEGRATION_BY_ID_QUERY,
    INTEGRATION_SERVICE_NAMES,
    BREADCRUMBS_LABELS,
    DELETE_INTEGRATION_MUTATION,
    GET_USER_PROFILE_QUERY,
    ROUTES,
} from 'Constants';
import { Breadcrumbs, Loader } from 'Components';

import { AwsDetails, WebhookDetails, AzureDetails } from './components';

import * as Styled from './styled';

export const IntegrationDetails = () => {
    const { id } = useParams();
    const history = useHistory();

    const [initialLoading, setInitialLoading] = useState(true);

    const { data: userData } = useQuery(GET_USER_PROFILE_QUERY);
    const user = userData?.getUserProfile;

    const { data: integrationData, loading, refetch } = useQuery(
        GET_INTEGRATION_BY_ID_QUERY,
        { variables: { integrationId: id } },
    );
    const details = integrationData?.getIntegrationById;

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    const [deleteIntegration, { loading: isDeleteLoadind }] = useMutation(
        DELETE_INTEGRATION_MUTATION,
        {
            variables: {
                owner: user?.owner,
                integrationId: details?._id,
                serviceName: details?.serviceName,
            },
            update: () => history.push(ROUTES.settings.integrations.main),
        },
    );

    if (initialLoading) {
        return <Loader />;
    }

    if (!details) {
        return 'There is no such integration';
    }

    let content = null;

    switch (details.serviceName) {
        case INTEGRATION_SERVICE_NAMES.AWS:
            content = (
                <AwsDetails
                    details={details}
                    deleteIntegration={deleteIntegration}
                    refetchIntegrationDetails={refetch}
                    isDeleteLoading={isDeleteLoadind}
                />
            );
            break;
        case INTEGRATION_SERVICE_NAMES.WEBHOOK:
            content = (
                <WebhookDetails
                    details={details}
                    deleteIntegration={deleteIntegration}
                    isDeleteLoading={isDeleteLoadind}
                />
            );
            break;
        case INTEGRATION_SERVICE_NAMES.Azure:
            content = (
                <AzureDetails
                    details={details}
                    deleteIntegration={deleteIntegration}
                    isDeleteLoading={isDeleteLoadind}
                />
            );
            break;
        default:
            break;
    }

    return (
        <Styled.Wrapper>
            <Breadcrumbs
                labels={BREADCRUMBS_LABELS.integrationDetails}
                entityName={details.name}
            />
            {content}
        </Styled.Wrapper>
    );
};
