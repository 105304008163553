import { useQuery } from '@apollo/client';
import { useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import { PageHeader, DevicesStarterPage } from 'Components';
import { ROUTES, GET_DEVICES_QUERY } from 'Constants';
import { hasValueLength } from 'Utils';

import { DeviceTable } from './components';
import * as Styled from './styled';

export const AllDevices = () => {
    const styledTheme = useContext(ThemeContext);
    const history = useHistory();

    const { data: arrayOfDevices, loading, refetch: refetchDevices } = useQuery(
        GET_DEVICES_QUERY,
        {
            variables: {
                paging: { items: 10, cursors: {} },
            },
            fetchPolicy: 'cache-and-network',
        },
    );

    const devices = useMemo(
        () => arrayOfDevices?.getDevices?.edges.map((item) => item.node) ?? [],
        [arrayOfDevices],
    );

    const cursorPagination = useMemo(
        () => arrayOfDevices?.getDevices?.pageInfo ?? {},
        [arrayOfDevices],
    );

    const withDevices = useMemo(
        () => hasValueLength(devices),
        [devices],
    );

    const description = withDevices
        ? 'The place  to manage all your IoT devices'
        : `Start your journey with ${styledTheme.whiteLabelCompany} by provisioning your devices now
            via USB`;

    const onRowClick = (record) => {
        const { main, deviceDetails } = ROUTES.devices;
        history.push(`${main}/${record.token}${deviceDetails.dashboard}`);
    };

    const paginationHandler = ({ cursors, items }) => {
        refetchDevices({ paging: { items, cursors } });
    };

    return (
        <Styled.Wrapper data-cy="devices-page-wrapper">
            <PageHeader title="All Devices" description={[description]} />
            <Styled.ContentWrapper>
                {withDevices ? (
                    <DeviceTable
                        onRowClick={onRowClick}
                        data={devices}
                        pagination={cursorPagination}
                        paginationHandler={paginationHandler}
                        deviceLoading={loading}
                    />
                ) : (
                    <DevicesStarterPage />
                )}
            </Styled.ContentWrapper>
        </Styled.Wrapper>
    );
};
