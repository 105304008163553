import PropTypes from 'prop-types';

import { Collapsible } from 'Components';

import { ProjectSettingsForm } from '../projectSettingsForm';
import { ProjectNetworksForm } from '../projectNetworksForm';
import { ProjectDeleteForm } from '../projectDeleteForm';
import { AdvancedSettings } from '../advancedSettings';

import * as Styled from './styled';

const collapsibleLabels = [
    {
        title: 'Project settings',
        description: 'Manage your project name and description',
    },
    {
        title: 'Networks',
        description: 'Setup and change your connectivity settings',
    },
    {
        title: 'Advanced',
        description: 'Manage your project access keys',
    },
    {
        title: 'Delete',
        description: 'Delete your project',
    },
];

export const ProjectConfiguration = ({ project }) => (
    <Styled.Wrapper>
        <Collapsible labels={collapsibleLabels}>
            <ProjectSettingsForm project={project} />
            <ProjectNetworksForm project={project} />
            <AdvancedSettings project={project} />
            <ProjectDeleteForm project={project} />
        </Collapsible>
    </Styled.Wrapper>
);

ProjectConfiguration.propTypes = {
    project: PropTypes.object,
};

ProjectConfiguration.defaultProps = {
    project: {},
};
