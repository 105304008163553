const expiredSessionName = 'pycom-expired-session';

export const setExpiredSession = () => {
    localStorage.setItem(expiredSessionName, true);
};

export const getExpiredSession = () => localStorage.getItem(expiredSessionName);

export const removeExpiredSession = () => {
    localStorage.removeItem(expiredSessionName);
};
