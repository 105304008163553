import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { ROUTES } from 'Constants';
import { ReactComponent as ComputerIcon } from 'Assets/icons/computer.svg';
import { ReactComponent as DeviceIcon } from 'Assets/icons/cpu.svg';
import { ReactComponent as InfoIcon } from 'Assets/icons/support.svg';

import * as Styled from './styled';

const {
    REACT_APP_PYCOM_FIRMWARE_UPDATER_RELEASES_PAGE,
} = process.env;

export const DeviceProvisioningOnline = () => {
    const styledTheme = useContext(ThemeContext);

    const { url } = useRouteMatch();
    const history = useHistory();

    const updateFirmware = () => {
        const id = url.split('/')[2];
        history.push(
            `${ROUTES.devices.firmware.split('/').slice(0, -1).join('/')}/${id}`,
        );
    };

    const descriptionText = `Provision the device to the
        ${styledTheme.whiteLabelCompany} platform using online
        Firmware Updater`;
    const listDescriptionText = ` in order to be able to see your USB
        port from ${styledTheme.whiteLabelCompany} platform.`;

    return (
        <Styled.Content>
            <Styled.Header>
                <Styled.Title>
                    Provisioning via Firmware Updater Online
                </Styled.Title>
                <Styled.Description>
                    {descriptionText}
                </Styled.Description>
            </Styled.Header>
            <Styled.List>
                <Styled.ListItem>
                    <Styled.Text>
                        <Styled.ListNumber>
                            1.
                        </Styled.ListNumber>
                        <Styled.ListDescription>
                            <a
                                href={
                                    REACT_APP_PYCOM_FIRMWARE_UPDATER_RELEASES_PAGE
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                            Download the Firmware Updater
                            </a> {listDescriptionText}
                        </Styled.ListDescription>
                    </Styled.Text>
                </Styled.ListItem>
                <Styled.ListItem>
                    <Styled.Text>
                        <Styled.ListNumber>
                            2.
                        </Styled.ListNumber>
                        <Styled.ListDescription>
                            Plug-in your Device to your computer via USB
                            <Styled.Connection>
                                <Styled.IconWrapper>
                                    <DeviceIcon />
                                </Styled.IconWrapper>
                                <Styled.ConnectionUsb />
                                <Styled.IconWrapper>
                                    <ComputerIcon />
                                </Styled.IconWrapper>
                            </Styled.Connection>
                        </Styled.ListDescription>
                    </Styled.Text>
                </Styled.ListItem>
                <Styled.Alert>
                    <InfoIcon />
                    <span>
                        Please, <strong>close</strong> Visual Studio Code or any other IDE
                        that might <strong>use the serial port</strong>.
                    </span>
                </Styled.Alert>
            </Styled.List>
            <Styled.Button onClick={updateFirmware}>
                Start provisioning
            </Styled.Button>
        </Styled.Content>
    );
};
