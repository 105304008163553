import styled from 'styled-components';

export const Card = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    gap: 1.5rem;

    width: 100%;

    padding: 1.5rem;
    background-color: #fafafa;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 0.5rem;
`;

export const Icon = styled.div`
    position: relative;
    flex-shrink: 0;
    width: 5rem;
    height: 5rem;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.active_light};

    & svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        font-size: 3rem;
        color: ${({ theme }) => theme.colors.homeIconColor};
    }
`;
export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.h1`
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 0.25rem;
    color: ${({ theme }) => theme.colors.title};
`;

export const Link = styled.span`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    font-size: 1rem;
    color: ${({ theme }) => theme.colors.text};

    & > svg {
        font-size: 1.5rem;
    }

    &:hover {
        color: ${({ theme }) => theme.colors.text};
        opacity: 0.8;
    }
`;
