import { Switch, Redirect, useRouteMatch } from 'react-router-dom';

import { ROUTES } from 'Constants';
import { ReactComponent as NetworksIcon } from 'Assets/icons/networks.svg';
import { ReactComponent as IntegrationsIcon } from 'Assets/icons/integrations.svg';
import { ReactComponent as MonitorSvg } from 'Assets/icons/monitor.svg';

import { RouteWrapper } from 'Components';

import {
    RootSettingsMenu,
    NetworkSettings,
    Integrations,
    PublicApi,
} from './components';

const links = [
    {
        title: 'Networks',
        route: ROUTES.settings.network.main,
        icon: NetworksIcon,
        cyData: 'settings-networks-tab',
    },
    {
        title: 'Integrations',
        route: ROUTES.settings.integrations.main,
        icon: IntegrationsIcon,
        cyData: 'settings-integrations-tab',
    },
    {
        title: 'Token Monitor',
        route: ROUTES.settings.openApi.logs,
        icon: MonitorSvg,
        cyData: 'settings-token-monitor-tab',
    },
];

export const SettingsModule = () => {
    const match = useRouteMatch();

    return (
        <div data-cy="settings-page-wrapper">
            <Switch>
                <Redirect exact from={match.path} to={ROUTES.settings.network.main} />
                <RootSettingsMenu links={links}>
                    <RouteWrapper
                        path={ROUTES.settings.network.main}
                        component={NetworkSettings}
                    />
                    <RouteWrapper
                        path={ROUTES.settings.integrations.main}
                        component={Integrations}
                    />
                    <RouteWrapper
                        path={ROUTES.settings.openApi.main}
                        component={PublicApi}
                    />
                </RootSettingsMenu>
            </Switch>
        </div>
    );
};
