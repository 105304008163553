import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Marker, InfoWindow } from '@react-google-maps/api';

import { getIconOptions, typesOfIcons } from './helpers';

export const CustomMarker = ({ position, typeOfIcon, color }) => {
    const [shouldShowInfo, setShouldShowInfo] = useState(false);

    const handleMouseOver = useCallback(() => {
        setShouldShowInfo(true);
    }, [setShouldShowInfo]);
    const handleMouseExit = useCallback(() => {
        setShouldShowInfo(false);
    }, [setShouldShowInfo]);

    return (
        <Marker
            position={position}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseExit}
            icon={getIconOptions(typesOfIcons[typeOfIcon], color)}
        >
            {shouldShowInfo && typeof position?.renderMetaInfo === 'function' && (
                <InfoWindow>
                    <div>{position.renderMetaInfo()}</div>
                </InfoWindow>
            )}
        </Marker>
    );
};

CustomMarker.defaultProps = {
    typeOfIcon: 'start',
    color: '',
};

CustomMarker.propTypes = {
    position: PropTypes.object.isRequired,
    color: PropTypes.string,
    typeOfIcon: PropTypes.string,
};
