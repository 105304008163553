import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { ReactComponent as BackArrow } from 'Assets/icons/arrowLeft.svg';
import { ReactComponent as Pycom } from 'Assets/ctrl-logo/blue.svg';
import { ReactComponent as BurgerIcon } from 'Assets/icons/burger-collapse.svg';

export const BurgerCollapse = styled(BurgerIcon)`
    display: none;
`;

export const SidebarTitle = styled.span`
    padding-left: 0;
    white-space: break-spaces;
    transition: padding-left 0.2s;
`;

export const Sidebar = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.colors.menu.background};
    transition: width 0.3s linear;
    display: flex;
    flex-direction: column;
    width: 265px;
    overflow-y: auto;
    border-right: 1px solid ${({ theme }) => theme.colors.border};
`;

export const SidebarContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    ul {
        display: flex;
        flex-direction: column;
        margin: 1.25rem;
        gap: 0.75rem;
    }
`;

export const PrimaryItem = styled(NavLink)`
    font-size: 14px;
    font-weight: 600;
    display: block;    
    height: 45px;
    border-radius: 8px;

    span {
        color: ${({ theme }) => theme.colors.menu.text};
    }

    svg {
        font-size: 20px;
        color: ${({ theme }) => theme.colors.menu.text};
    }

    &.active {
        background-color: ${({ theme }) => theme.colors.menu.active_background};
        color: ${({ theme }) => theme.colors.menu.active_txt};
        transition: width 0.4s linear;

        span,
        svg {
            color: ${({ theme }) => theme.colors.menu.active_txt};
        }
    }
`;

export const SecondaryItem = styled(NavLink)`
    font-size: 14px;
    font-weight: 400;
    display: block;    
    height: 35px;
    border-radius: 8px;

    span {
        color: ${({ theme }) => theme.colors.menu.text};
    }

    svg {
        font-size: 20px;
        color: ${({ theme }) => theme.colors.menu.text};
    }

    &.active {
        background-color: ${({ theme }) => theme.colors.active_light};
        color: ${({ theme }) => theme.colors.active};
        transition: width 0.4s linear;

        span,
        svg {
            color: ${({ theme }) => theme.colors.active};
        }
    }
`;

export const Separator = styled.span`
    display: block;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.border};
`;

export const Content = styled.div`
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 0.75rem;
    height: 100%;
    align-items: center;
    gap: 0.75rem;
`;

export const Arrow = styled(BackArrow)`
    transform: rotate(90deg) !important;
    position: absolute;
    font-size: 30px;
    color: ${({ theme }) => theme.colors.menu.text};

    ${({ $isAnimated }) => $isAnimated && `
        transform: rotate(90deg);
    `}
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    color: ${({ theme }) => theme.colors.text};
    padding: 0.75rem 1.25rem;
`;

export const Copyright = styled.p`
    font-size: 0.75rem;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: 0;
    opacity: 0.5;
`;

export const PycomLogo = styled(Pycom)`
    width: 60px;
    height: auto;
    color: ${({ theme }) => theme.colors.text};
`;
export const SidebarTop = styled.div`
    width: 100%;
`;

export const Logo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 1.25rem;
    width: 100%;
    height: 80px;
`;

export const Link = styled.a`
    svg {
        opacity: 0;
        transition: 0.2s;
    }
    &:hover svg {
        opacity: 1;
    }
    &:hover ${SidebarTitle} {
        text-decoration: underline;
    }
`;
