import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useApolloClient } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { PageHeader, Table, projectColumns, releaseColumns, Modal, Loader } from 'Components';
import { pymakrHeader, GET_APPLICATIONS, GET_RELEASES_QUERY, GET_RELEASE_FILES_QUERY, GET_USER_PROFILE_QUERY, ROUTES } from 'Constants';
import { prepareEditorStructure, setReleaseModeInfo, showToastError } from 'Utils';
import { PymakrEditor } from '../pymakrEditor';
import * as Styled from './styled';

export const PymakrProject = () => {
    const history = useHistory();
    const client = useApolloClient();
    const { appId, relId } = useParams();

    const [releases, setReleases] = useState([]);
    const [releaseModalOpen, setReleaseModalOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedRelease, setSelectedRelease] = useState(null);
    const [editorTitle, setEditorTitle] = useState(null);
    const [files, setFiles] = useState(null);

    const { title, description } = pymakrHeader;

    const { data: userData } = useQuery(GET_USER_PROFILE_QUERY);
    const user = userData?.getUserProfile?.owner;

    const { data: getApplicationsData } = useQuery(GET_APPLICATIONS);
    const applications = getApplicationsData?.getApplications ?? [];

    const onCloseReleaseModal = () => {
        setReleaseModalOpen(false);
    };

    const onProjectSelect = async (project, withModal = true) => {
        setSelectedProject(project);

        const { data: getReleasesData } = await client.query({
            query: GET_RELEASES_QUERY,
            variables: { applicationId: project._id },
        });

        setReleases(getReleasesData.getReleases);

        if (withModal) {
            setReleaseModalOpen(true);
        }
    };

    const onReleaseSelect = async (applicationId, releaseId) => {
        try {
            const { data: getReleaseFilesData } = await client.query({
                query: GET_RELEASE_FILES_QUERY,
                variables: { applicationId, releaseId },
            });

            const newEditorTitle = `${selectedProject.name}.v.${selectedRelease.releaseVersion}`;
            setEditorTitle(newEditorTitle);
            let processedFiles;

            const storedData = localStorage.getItem(`${user}-${newEditorTitle}`);
            if (storedData) {
                const { hierarchy } = JSON.parse(storedData);
                processedFiles = hierarchy;
            }

            if (!processedFiles) {
                processedFiles = prepareEditorStructure(newEditorTitle, getReleaseFilesData.getReleaseFiles, user);
            }

            setFiles(processedFiles);
            if (isEmpty(processedFiles?.files)) {
                return showToastError(`Please, choose another release because ${processedFiles?.name} doesn't include source code files.`);
            }

            setReleaseModalOpen(false);
            history.push(`${ROUTES.pymakr.project}/${applicationId}/release/${releaseId}`);
        } catch (error) {
            console.warn(error);
        }
    };

    useEffect(() => {
        if (applications.length && appId) {
            const applicationIndex = applications.findIndex((application) => application._id === appId);
            if (applicationIndex >= 0) {
                const project = applications[applicationIndex];
                setSelectedProject(project);
                onProjectSelect(project, false);
            }
        }
    }, [applications]);

    useEffect(() => {
        if (releases.length && relId) {
            const releaseIndex = releases.findIndex((release) => release.codeFilename === relId);
            if (releaseIndex >= 0) {
                const release = releases[releaseIndex];
                setSelectedRelease(release);
            }
        }
    }, [releases]);

    useEffect(() => {
        if (selectedRelease) {
            onReleaseSelect(selectedProject._id, selectedRelease.codeFilename);
        }
    }, [selectedRelease]);

    if (!appId || !relId) {
        return (
            <Styled.Wrapper>
                <PageHeader title={title} description={[description]} />
                <h1>Choose a project what do you want to open in CtrlR</h1>
                <Styled.Table>
                    <Table
                        columns={projectColumns}
                        data={applications}
                        onRowClick={onProjectSelect}
                    />
                </Styled.Table>
                <Modal
                    isOpened={releaseModalOpen}
                    handleClose={onCloseReleaseModal}
                >
                    <Styled.Header>Choose a release</Styled.Header>
                    <Styled.ReleaseTable>
                        <Table
                            columns={releaseColumns}
                            data={releases}
                            onRowClick={(release) => setSelectedRelease(release)}
                        />
                    </Styled.ReleaseTable>
                </Modal>
            </Styled.Wrapper>
        );
    }

    if (files?.length || files !== null) {
        const projectKey = `${user}-${editorTitle}`;
        const providedCompilation = selectedRelease.customFirmwares.filter((firmware) => firmware.compilationId)?.[0]?.compilationId || '';
        setReleaseModeInfo(projectKey, appId, relId, editorTitle, user);

        return (
            <PymakrEditor
                projectKey={projectKey}
                title={editorTitle}
                initialStructure={files}
                user={user}
                providedCompilation={providedCompilation}
            />
        );
    }

    return <Loader />;
};
