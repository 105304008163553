import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useState, useEffect, useContext } from 'react';
import isEmpty from 'lodash/isEmpty';
import { ThemeContext } from 'styled-components';

import {
    GET_DEVICE_BY_TOKEN_QUERY,
    GET_ACTIVATION_TOKEN_QUERY,
    ACTIVATION_OVER_WIFI,
    ACTIVATION_OVER_LTE,
} from 'Constants';
import { ClickBoard, GenerationToken, Loader } from 'Components';
import { showToastSuccess } from 'Utils';

import * as Styled from './styled';

export const DeviceProvisioningPymakr = ({ deviceToken }) => {
    const styledTheme = useContext(ThemeContext);

    const { data: dataOfDevice, loading: deviceLoading } = useQuery(GET_DEVICE_BY_TOKEN_QUERY, {
        variables: {
            deviceToken,
        },
    });
    const { data: activationToken, loading: tokenLoading } = useQuery(GET_ACTIVATION_TOKEN_QUERY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            deviceToken,
        },
    });

    const [activationString, setActivationString] = useState('');
    const [title, setTitle] = useState('');

    const token = activationToken?.getActivationToken ?? '';
    const device = dataOfDevice?.device[0] ?? {};

    const copyActivationString = () => {
        showToastSuccess('Copied');
    };

    const generateDeviceData = () => {
        if (token?.length) {
            let activationDeviceInfo;
            const { wifi, lte } = device;

            if (!isEmpty(wifi)) {
                activationDeviceInfo = {
                    s: wifi.ssid,
                    p: wifi.password,
                    a: token,
                };
                setTitle(ACTIVATION_OVER_WIFI);
            }
            if (!isEmpty(lte)) {
                const { carrier, band, cid, apn, protocol, name, reset } = lte;
                activationDeviceInfo = {
                    band,
                    cid,
                    apn,
                    reset,
                    name,
                    type: protocol,
                    carrier: carrier.toLowerCase(),
                    a: token,
                };
                setTitle(ACTIVATION_OVER_LTE);
            }
            const base64String = Buffer.from(
                JSON.stringify(activationDeviceInfo),
            ).toString('base64');
            setActivationString(`ctrl.activate("${base64String}")`);
        }
    };

    useEffect(() => {
        generateDeviceData();
    }, [token]);

    if (deviceLoading || tokenLoading) {
        return <Loader />;
    }

    const descriptionText = `Provision the device to the
        ${styledTheme.whiteLabelCompany} platform using CTRLR
        Plugin`;

    return (
        <Styled.Content>
            <Styled.Header>
                <Styled.Title>
                    Provisioning via CTRLR Plugin
                </Styled.Title>
                <Styled.Description>
                    {descriptionText}
                </Styled.Description>
            </Styled.Header>
            <Styled.List>
                <Styled.ListItem>
                    <Styled.Text>
                        <Styled.ListNumber>
                            1.
                        </Styled.ListNumber>
                        <Styled.ListDescription>
                            Install CtrlR plugin in Visual Studio Code
                        </Styled.ListDescription>
                    </Styled.Text>
                </Styled.ListItem>
                <Styled.ListItem>
                    <Styled.Text>
                        <Styled.ListNumber>
                            2.
                        </Styled.ListNumber>
                        <Styled.ListDescription>
                            Connect your device with USB to CtrlR
                        </Styled.ListDescription>
                    </Styled.Text>
                </Styled.ListItem>
                <Styled.ListItem>
                    <Styled.Text>
                        <Styled.ListNumber>
                            3.
                        </Styled.ListNumber>
                        <Styled.ListDescription>
                            Copy and paste this activation string into the
                            CtrlR window
                        </Styled.ListDescription>
                    </Styled.Text>
                </Styled.ListItem>
                {!token?.length ? (
                    <GenerationToken deviceToken={deviceToken} type="PYMAKR" />
                ) : (
                    <div>
                        <Styled.DescriptionHeader>
                            {title.toUpperCase()}
                        </Styled.DescriptionHeader>
                        <Styled.DescriptionClickboard>
                            <ClickBoard
                                text={activationString}
                                handleCopy={copyActivationString}
                            />
                        </Styled.DescriptionClickboard>
                    </div>
                )}
            </Styled.List>
        </Styled.Content>
    );
};

DeviceProvisioningPymakr.propTypes = {
    deviceToken: PropTypes.string.isRequired,
};
