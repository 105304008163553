import { useEffect, useState, useRef, useContext } from 'react';
import mqtt from 'mqtt';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { Formik } from 'formik';
import { useQuery, useSubscription, useMutation } from '@apollo/client';

import {
    FIRMWARE_QUERY,
    GET_USER_PROFILE_QUERY,
    TYPE_OTA,
    TYPE_MASK,
    FCOTA_PING_SUBSCRIPTION,
    OFFLINE,
    ONLINE,
    PING_FCOTA_MUTATION,
} from 'Constants';
import { showToastSuccess, showToastError } from 'Utils';
import { AlertBordered, Loader } from 'Components';

import { getDeviceFirmwareStatus, FIRMWARE_STATUS } from '../../config';

import { DOWNGRADE_LINK, getFirmwareValues } from './config';
import { FormContent } from './formContent';

export const DeviceFirmware = ({
    device,
    currentFirmwareVersion,
    updateFirmwareStatus,
    updateCurrentFirmwareVersion,
}) => {
    const styledTheme = useContext(ThemeContext);
    const { data: { getUserProfile: user } } = useQuery(GET_USER_PROFILE_QUERY);
    const { data: firmwareQueryData, loading: firmwareLoading } = useQuery(FIRMWARE_QUERY);
    const { data: pingData } = useSubscription(FCOTA_PING_SUBSCRIPTION, {
        variables: { deviceToken: device.token },
        fetchPolicy: 'network-only',
    });

    const [pingFCOTAMutation] = useMutation(PING_FCOTA_MUTATION, {
        variables: { deviceToken: device.token },
    });

    const firmwareLatestVersion = firmwareQueryData?.getLatestFirmware;
    const { version } = currentFirmwareVersion;
    const [firmwareValues, setFirmwareValues] = useState([
        {
            value: version,
            label: version,
        },
    ]);

    const pingTimeout = useRef(null);
    const processTimeout = useRef(null);
    const connectionStateTimeout = useRef(null);
    const [updating, setUpdating] = useState(false);
    const [connectionState, setConnectionState] = useState(OFFLINE);

    const pingReceived = (activity) => {
        clearTimeout(connectionStateTimeout.current);
        if (activity === '') {
            setConnectionState(ONLINE);
        }
    };

    const waitingForResponse = () => {
        connectionStateTimeout.current = setTimeout(() => {
            setConnectionState(OFFLINE);
        }, 2000);
    };

    const pingFCOTA = () => {
        pingTimeout.current = setTimeout(() => {
            waitingForResponse();
            pingFCOTAMutation();
            pingFCOTA();
        }, 4000);
    };

    useEffect(() => {
        if (pingData) {
            pingReceived(pingData?.pingSubscription?.activity);
        }
    }, [pingData]);

    useEffect(async () => {
        const newFirmwareValues = await getFirmwareValues(currentFirmwareVersion, device.mac);
        setFirmwareValues(newFirmwareValues);
    }, [currentFirmwareVersion]);

    useEffect(() => {
        pingFCOTA();

        return () => {
            clearTimeout(pingTimeout.current);
            clearTimeout(connectionStateTimeout.current);
            clearTimeout(processTimeout.current);
        };
    }, []);

    if (firmwareLoading) {
        return <Loader />;
    }

    // this method is called when the update process took too long to be considered as a normal behavior
    const updateTimeout = () => {
        showToastError('The update process timed out');
        setUpdating(false);
        updateFirmwareStatus(getDeviceFirmwareStatus(currentFirmwareVersion, firmwareLatestVersion));
    };

    // The update succeeded
    const updateSuccess = (msg) => {
        showToastSuccess(msg);
        // Updating the status and the firmware version manually after success, because
        // of waiting for the subscription, it is a delay and in this case the user see incorrect status
        updateFirmwareStatus(FIRMWARE_STATUS.UP_TO_DATE);
        updateCurrentFirmwareVersion(firmwareLatestVersion);
        clearTimeout(processTimeout.current);
        setUpdating(false);
    };

    // The update failed
    const updateFailed = (msg) => {
        showToastError(`${device.description}: ${msg}`);
        updateFirmwareStatus(getDeviceFirmwareStatus(currentFirmwareVersion, firmwareLatestVersion));
        clearTimeout(processTimeout.current);
        setUpdating(false);
    };

    const startUpdate = (values) => {
        setUpdating(true);
        updateFirmwareStatus(FIRMWARE_STATUS.UPDATING);
        clearTimeout(processTimeout.current);
        const { token } = device;
        const { owner } = user;

        const messagePayload = new Uint8Array(1);

        const USER_SYSTEM_MASK = 0x80; // 1000 0000

        let header = USER_SYSTEM_MASK;
        // eslint-disable-next-line no-bitwise
        header |= (TYPE_OTA & TYPE_MASK);

        messagePayload[0] = header;

        const mqttClientOptions = {
            clean: true,
            username: owner,
            password: token,
        };
        const client = mqtt.connect(process.env.REACT_APP_MQTT_SERVER_AEDES_WEB_SOCKET_URL, mqttClientOptions);

        client.on('connect', () => {
            client.unsubscribe(`u${token}/ota`);
            client.subscribe(`u${token}/ota`);
            client.publish(`d${token}`, Buffer.from(messagePayload), { qos: 0 }, (err) => {
                if (err) {
                    updateFailed(`Error: ${err.message}`);
                }
            });
        });

        client.on('message', (topic, recvMessage) => {
            if (recvMessage?.length === 2) {
                switch (recvMessage[1]) {
                    case 1: updateFailed('Already up-to-date'); break;
                    case 2:
                        updateSuccess(`${device.description} is now using firmware v${values.version}. The device is restarting...`);
                        break;
                    case 0: updateFailed('An error occurred'); break;
                    default: break;
                }
            } else {
                updateFailed('An error occurred');
            }

            client.end();
        });

        // timeout of 5 minutes
        processTimeout.current = setTimeout(updateTimeout, 300000);
    };

    const initialValues = {
        version,
    };

    const getAlert = () => {
        let title = 'Up To Date';
        let type = 'success';
        let message = (
            <span>
                To downgrade your firmware follow&nbsp;
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={DOWNGRADE_LINK}
                >these instructions
                </a>
            </span>
        );
        const status = getDeviceFirmwareStatus(currentFirmwareVersion, firmwareLatestVersion);
        if (status === FIRMWARE_STATUS.NO_INFORMATION && connectionState === ONLINE) {
            title = 'No Information';
            type = 'warning';
            message = (
                <span>
                    Your device was probably never connected to ${styledTheme.whiteLabelCompany} before,
                    so ${styledTheme.whiteLabelCompany} cannot know the firmware version.
                    <br />
                    If you are having trouble connecting your device to ${styledTheme.whiteLabelCompany}
                    take a look at CONFIGURATION panel above.
                </span>
            );
        } else if (updating) {
            title = 'Updating';
            type = 'info';
            message = (
                <span>
                    The device has received the command to download and install the requested firmware version.
                    <br />
                    <br />
                    The process should not &apos;t be longer than 30 seconds.
                    If it takes longer, ${styledTheme.whiteLabelCompany} will consider that the
                    update procedure has failed. However, if the network is really slow for the device, the update might
                    eventually come to an end after a few minutes.
                </span>
            );
        } else if (status === FIRMWARE_STATUS.UPDATE_AVAILABLE && connectionState === ONLINE) {
            title = 'Update Available';
            type = 'error';
            message = (
                <span>
                    This device is currently using the version <b>{version}</b> of the firmware,
                    while <b>{firmwareLatestVersion.version}</b> is the most recent version available.
                    <br />
                    <br />
                    You can choose which version of the firmware you want to install on this device with by selecting a
                    different version in the dropdown menu below, and pressing the update button.
                </span>
            );
        } else if (connectionState === OFFLINE) {
            title = 'Device is offline';
            type = 'info';
            message = (
                <span>
                    You can&apos;t update firmware for this device, because the
                    device is offline.
                </span>
            );
        }

        return (
            <AlertBordered
                title={title}
                message={message}
                type={type}
            />
        );
    };

    return (
        <Formik
            onSubmit={startUpdate}
            initialValues={initialValues}
            enableReinitialize
        >
            {(props) => (
                <FormContent
                    {...props}
                    currentFirmwareVersion={version}
                    firmwareValues={firmwareValues}
                    updating={updating}
                    alert={getAlert()}
                    isOnline={connectionState === ONLINE}
                />
            )}
        </Formik>
    );
};

DeviceFirmware.propTypes = {
    device: PropTypes.object.isRequired,
    currentFirmwareVersion: PropTypes.shape({
        version: PropTypes.string,
        intVersion: PropTypes.number,
    }).isRequired,
    updateFirmwareStatus: PropTypes.func.isRequired,
    updateCurrentFirmwareVersion: PropTypes.func.isRequired,
};
