import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import { ReactComponent as SettingsIcon } from 'Assets/widgets/settings.svg';
import * as Styled from './styled';

import { Checkbox } from '../../inputs';

export const ChartLayoutSettings = ({
    drawGrid,
    steppedLine,
    fillArea,
    displayPoints,
    setDrawGrid,
    setSteppedLine,
    setFillArea,
    setDisplayPoints,
}) => {
    const [isChartSettingsShown, setIsChartSettingsShown] = useState(false);

    const handleDrawGrid = () => {
        setDrawGrid(!drawGrid);
    };

    const handleSteppedLine = () => {
        setSteppedLine(!steppedLine);
    };

    const handleFillArea = () => {
        setFillArea(!fillArea);
    };

    const handleDisplayPoints = () => {
        setDisplayPoints(!displayPoints);
    };

    const tooltipProperties = {
        trigger: ['click'],
        color: 'white',
        overlayClassName: 'chart-layout-settings-tooltip',
        overlayInnerStyle: {
            color: '#828282',
            boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.15)',
            borderRadius: '10px',
            padding: '24px 64px 13px 18px',
        },
        getPopupContainer: (triggerNode) => triggerNode.parentNode,
    };

    return (
        <>
            <Styled.CustomTooltipStyle />
            <Tooltip
                placement="bottomRight"
                {...tooltipProperties}
                title={() => (
                    <>
                        <Styled.CheckboxWrapper
                            $checked={drawGrid}
                        >
                            <Checkbox
                                title="Draw Grid on Graph"
                                checked={drawGrid}
                                handleCheckbox={handleDrawGrid}
                            />
                        </Styled.CheckboxWrapper>
                        <Styled.CheckboxWrapper
                            $checked={steppedLine}
                        >
                            <Checkbox
                                title="Stepped Line"
                                checked={steppedLine}
                                handleCheckbox={handleSteppedLine}
                            />
                        </Styled.CheckboxWrapper>
                        <Styled.CheckboxWrapper
                            $checked={fillArea}
                        >
                            <Checkbox
                                title="Fill Graph Area"
                                checked={fillArea}
                                handleCheckbox={handleFillArea}
                            />
                        </Styled.CheckboxWrapper>
                        <Styled.CheckboxWrapper
                            $checked={displayPoints}
                        >
                            <Checkbox
                                title="Display Points"
                                checked={displayPoints}
                                handleCheckbox={handleDisplayPoints}
                            />
                        </Styled.CheckboxWrapper>
                    </>
                )}
                onOpenChange={(visible) => {
                    setIsChartSettingsShown(visible);
                }}
            >
                <Styled.Icon
                    $chartSettingsSelected={isChartSettingsShown}
                >
                    <SettingsIcon />
                </Styled.Icon>
            </Tooltip>
        </>
    );
};

ChartLayoutSettings.propTypes = {
    drawGrid: PropTypes.bool.isRequired,
    steppedLine: PropTypes.bool.isRequired,
    fillArea: PropTypes.bool.isRequired,
    displayPoints: PropTypes.bool.isRequired,
    setDrawGrid: PropTypes.func.isRequired,
    setSteppedLine: PropTypes.func.isRequired,
    setFillArea: PropTypes.func.isRequired,
    setDisplayPoints: PropTypes.func.isRequired,
};
