import styled from 'styled-components';

export const Subtitle = styled.div`
    margin: -20px auto 50px;
    margin-bottom: 50px;
    font-size: 16px;
    text-align: center;
    max-width: 300px;
`;

export const ButtonWrapper = styled.div`
    margin-top: 50px;
`;

export const AuthInput = styled.div`
    margin-bottom: 25px;
`;

export const ValidatorWrapper = styled.div`
    ul {
        margin: 0 0 10px;
    }
`;
