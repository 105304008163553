import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';

import { PageHeader, Button, BaseFileUploader, Spin } from 'Components';
import { ReactComponent as Support } from 'Assets/icons/support.svg';
import {
    fileToBase64,
    prepareEditorStructure,
    showToastError,
    prepareFiles,
    clearLocalStorageByCondition,
} from 'Utils';
import {
    UPLOAD_ZIP_FILE,
    MAX_SIZE_FILE,
    GET_USER_PROFILE_QUERY,
    pymakrHeader,
    ROUTES,
    ZIP_EXTENSIONS,
} from 'Constants';
import { VaultService } from 'Services';

import * as Styles from './styled';

export const PymakrZipFile = () => {
    const history = useHistory();
    const { data: userData } = useQuery(GET_USER_PROFILE_QUERY);
    const [uploadFileZip, { data, loading }] = useLazyQuery(UPLOAD_ZIP_FILE);
    const [disabled, setDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [archive, setArchive] = useState(null);
    const [preparedProject, setPreparedProject] = useState({});
    const [base64Archive, setBase64Archive] = useState(null);
    const hierarchy = data?.uploadZipFile ?? {};
    const user = userData?.getUserProfile ?? {};
    const storageRef = useRef();

    useEffect(() => {
        if (!storageRef.current) {
           storageRef.current = new VaultService();
        }
    }, []);

    useEffect(() => {
        if (!isEmpty(hierarchy)) {
            const hierarchyProject = JSON?.parse(hierarchy);
            setPreparedProject(hierarchyProject);
        }
    }, [hierarchy]);

    const getArchiveToBase64 = async () => {
        try {
            const fileBase64 = await fileToBase64(archive);
            setBase64Archive(fileBase64);
        } catch (error) {
            showToastError(error);
        }
    };

    useEffect(() => {
        if (archive) {
            getArchiveToBase64();
            setDisabled(false);
        }
    }, [archive]);

    useEffect(() => {
        if (!isEmpty(preparedProject)) {
            const key = `${user?.owner}-${preparedProject?.title}`;
            clearLocalStorageByCondition(key);
            const newFiles = prepareFiles(preparedProject);
            const title = preparedProject?.title || archive.name.split('.')[0];
            const structure = prepareEditorStructure(
                title,
                newFiles,
                user.owner,
            );
            const currentValue = storageRef.current.getItem(key) || {};
            storageRef.current.setItem(key, { ...currentValue, hierarchy: structure });
            history.push(`${ROUTES.pymakr.zipFile}/${structure?.name}`);
        }
    }, [preparedProject]);

    const handleClick = async () => {
        try {
            setIsLoading(true);
            await uploadFileZip({
                fetchPolicy: 'no-cache',
                variables: {
                    zip: base64Archive,
                    name: archive.name,
                },
                onError: (error) => {
                    console.warn(error);
                    showToastError('Invalid zip file');
                },
            });
        } catch (error) {
            console.warn(error);
            showToastError(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Spin spinning={isLoading}>
            <PageHeader
                title={pymakrHeader.title}
                description={[pymakrHeader.description]}
            />
            <Styles.Content>
                <Styles.ContentTitle>
                    Upload a Zip file of your project
                </Styles.ContentTitle>
                <Styles.ContentDescription>
                    <Support />
                    <div>
                        You can include your code into release as a zip file.
                    </div>
                </Styles.ContentDescription>
                <Styles.File>
                    <Styles.FileTitle>Upload your file</Styles.FileTitle>
                    <BaseFileUploader
                        expectedExtensions={ZIP_EXTENSIONS.join(', ')}
                        onChange={setArchive}
                        maxSize={MAX_SIZE_FILE}
                    />
                </Styles.File>
                <Button disabled={disabled} onClick={handleClick} loading={loading}>
                    Open Zip in CtrlR
                </Button>
            </Styles.Content>
        </Spin>
    );
};
