import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Actions } from 'Assets/icons/actions.svg';
import * as Styled from './styled';

export const Dropdown = ({ actions, Icon, id, className, cyData }) => {
    const propagationStopper = (event, func) => {
        event.stopPropagation();
        func && func(id);
    };

    const MenuItems = actions.map((action, index) => ({
            key: action.cyData,
            label: (
                <Fragment key={action.title}>
                    <Styled.Menu
                        key={action.title}
                        onClick={({ domEvent }) => propagationStopper(domEvent, action.executedFunction)}
                        data-cy={action.cyData}
                        disabled={action.disabled}
                    >
                        {action.title}
                    </Styled.Menu>
                    {index < actions.length - 1 && (
                        <Styled.Divider />
                    )}
                </Fragment>
            ),
        }));

    return (
        <Styled.Dropdown
            menu={{ items: MenuItems }}
            trigger="click"
            onClick={propagationStopper}
        >
            <Styled.Button icon={<Icon />} className={className} data-cy={cyData} />
        </Styled.Dropdown>
    );
};

Dropdown.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.shape({
        executedFunction: PropTypes.func,
        title: PropTypes.string,
    })).isRequired,
    Icon: PropTypes.elementType,
    id: PropTypes.string,
    className: PropTypes.string,
    cyData: PropTypes.string,
};

Dropdown.defaultProps = {
    Icon: Actions,
    id: '',
    className: '',
    cyData: null,
};
