import { useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { useQuery } from '@apollo/client';

import { GET_ALL_FILTERED_DEVICES_QUERY } from 'Constants';
import { Loader } from '../../../loader';

import { Table } from '../../../table';
import { Button } from '../../../buttons';
import * as Styled from '../styled';

import { columns, mergeValues } from './config';

export const ModelDeviceSelection = ({
    nextStep,
    prevStep,
    formValues,
    isLoading,
    hideBackButton,
    hideTitle,
    excludeDevices,
}) => {
    const styledTheme = useContext(ThemeContext);

    const { data: allDevicesData, loading } = useQuery(GET_ALL_FILTERED_DEVICES_QUERY, { variables: { filter: {} } });
    const devices = allDevicesData?.getAllDevices ?? [];

    const filteredDevices = useMemo(
        () => devices.filter((device) => !excludeDevices.some((item) => item.token === device.token)),
        [devices, excludeDevices],
    );

    const [selectedIds, setSelectedIds] = useState(formValues?.devices?.map((device) => (device.deviceId)) ?? []);
    const [tableDataSource, setTableDataSource] = useState(filteredDevices);

    useEffect(() => {
        setTableDataSource(filteredDevices);
    }, [filteredDevices]);

    const onSubmit = () => nextStep(mergeValues(formValues, selectedIds, filteredDevices));
    const onBack = () => prevStep(mergeValues(formValues, selectedIds, filteredDevices));

    const onSearch = (searchValue) => {
        if (searchValue) {
            const filteredData = filteredDevices
                .filter((device) => device.description.toLowerCase().includes(searchValue.toLowerCase()));
            setTableDataSource(filteredData);
        } else {
            setTableDataSource(filteredDevices);
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            {!hideTitle && (
                <Styled.Title>
                    Select Devices for the Model
                </Styled.Title>
            )}
            <Styled.NotificationWrapperTop>
                <Styled.NotificationLabel>
                    In the table below you will find all the Devices that were provisioned
                    to the ${styledTheme.whiteLabelCompany} before.
                </Styled.NotificationLabel>
            </Styled.NotificationWrapperTop>
            <div>
                <Styled.DevicesTableWrapper>
                    <Table
                        columns={columns}
                        data={tableDataSource}
                        onSelect={setSelectedIds}
                        selectedRowKeys={selectedIds}
                        searchOptions={{ onSearch, placeholder: 'Search for device' }}
                        maxBodyHeight={165}
                    />
                </Styled.DevicesTableWrapper>
            </div>
            <Styled.ButtonsWrapper>
                {!hideBackButton && (
                    <Button
                        buttonType="transparent"
                        onClick={onBack}
                    >
                        Back
                    </Button>
                )}
                <Button
                    type="submit"
                    disabled={!selectedIds.length}
                    onClick={onSubmit}
                    loading={isLoading}
                >
                    Finish
                </Button>
            </Styled.ButtonsWrapper>
        </>
    );
};

ModelDeviceSelection.propTypes = {
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func,
    formValues: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    hideBackButton: PropTypes.bool,
    hideTitle: PropTypes.bool,
    excludeDevices: PropTypes.array,
};

ModelDeviceSelection.defaultProps = {
    isLoading: false,
    prevStep: () => {},
    hideBackButton: false,
    hideTitle: false,
    excludeDevices: [],
};
