import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, Marker } from '@react-google-maps/api';
import isEmpty from 'lodash/isEmpty';

import { CustomMarker, CustomPath, getBounds } from './components';
import { defaultVisualOptions } from './config';

export const Map = ({ visualOptions, locations, location, colorOfDefaultMarker }) => {
    const mapRef = useRef(null);
    const [center, setCenter] = useState(null);
    const [mapsLoaded, setMapsLoaded] = useState(false);

    useEffect(
        () => {
            if (mapsLoaded) {
                if (locations.length && !isEmpty(mapRef.current)) {
                    let bounds;
                    if (Array.isArray(locations[0]) && locations.length === 1) {
                        bounds = getBounds(locations[0]);
                        if (locations[0].length === 1) {
                            const centerPosition = bounds.getCenter();
                            return setCenter(centerPosition);
                        }
                        return mapRef.current.fitBounds(bounds);
                    }
                    if (Array.isArray(locations[0]) && locations.length > 1) {
                        const allLocations = locations.flat();
                        bounds = getBounds(allLocations);
                        return mapRef.current.fitBounds(bounds);
                    }
                    if (locations[0] instanceof Object && locations.length > 1) {
                        const allLocations = locations.map((item) => item.location);
                        bounds = getBounds(allLocations);
                        return mapRef.current.fitBounds(bounds);
                    }
                    bounds = getBounds(locations[0].location);
                    const centerPosition = bounds.getCenter();
                    return setCenter(centerPosition);
                }
                if (location) {
                    const bounds = getBounds(location);
                    const centerPosition = bounds.getCenter();
                    return setCenter(centerPosition);
                }
            }
        },
        [mapsLoaded, locations, location],
    );

    return (
        <GoogleMap
            mapContainerStyle={visualOptions}
            zoom={14}
            center={center}
            onLoad={(map) => {
                mapRef.current = map;
                setMapsLoaded(true);
            }}
        >
            {!!locations.length && (
                locations.map((item, index) => {
                    if (!(Array.isArray(item))) {
                        return (
                            <CustomMarker
                                key={item.key}
                                position={item.location}
                                typeOfIcon="start"
                                color={colorOfDefaultMarker}
                            />
                        );
                    }

                    if (item.length > 1) {
                        return (
                            <CustomPath
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                locations={item}
                            />
                        );
                    }
                    return (
                        <CustomMarker
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            position={item[0]}
                            typeOfIcon="start"
                            color={colorOfDefaultMarker}
                        />
                    );
                })
            )}
            {!locations.length && location && (
                <Marker position={location} />
            )}
        </GoogleMap>
    );
};

Map.defaultProps = {
    visualOptions: defaultVisualOptions,
    locations: [],
    location: null,
    colorOfDefaultMarker: '',
};

Map.propTypes = {
    visualOptions: PropTypes.object,
    locations: PropTypes.array,
    location: PropTypes.object,
    colorOfDefaultMarker: PropTypes.string,
};
