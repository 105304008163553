import { ReactComponent as AwsIcon } from 'Assets/tiles/aws.svg';
import { ReactComponent as WebHookIcon } from 'Assets/tiles/webhook.svg';
import { ReactComponent as AzureIcon } from 'Assets/tiles/azure.svg';
import { INTEGRATION_SERVICE_NAMES } from 'Constants';

export const integrationIcons = {
    AWS: AwsIcon,
    WebHook: WebHookIcon,
    Azure: AzureIcon,
};

export const getIntegrationName = (integration) => {
    switch (integration.serviceName) {
        case INTEGRATION_SERVICE_NAMES.AWS:
            let displayName = integration.name.split('-');
            displayName.splice(0, 2);
            displayName = displayName.join('-');
            return displayName;
        default:
            return integration.name;
    }
};
