import styled from 'styled-components';

export const Header = styled.div`
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    background-color: ${({ theme }) => theme.colors.menu.background};
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    z-index: 2;
`;

export const Menu = styled.div`
    display: flex;
    align-items: center;
    gap: calc(3rem + 1px);

    & > *:not(:last-of-type) {
        position: relative;
    }
    & > *:not(:last-of-type):before {
        position: absolute;
        content: '';
        right: calc(-1.5rem - 1px);
        width: 1px;
        height: 32px;
        background-color: ${({ theme }) => theme.colors.border};
        top: calc(50% - 16px);
    }
`;

export const CircleIcon = styled.div`
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    background-color: ${({ theme }) => theme.colors.active_light};
    border-radius: 50%;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.25rem;
        color: ${({ theme }) => theme.colors.menu.active_txt};
    }
`;

export const Logo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.25rem;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    height: 80px;
`;
