import { useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { login } from 'Api';
import { ROUTES } from 'Constants';
import { showToastError, showToastSuccess, showToastInfo } from 'Utils';

import * as RootStyled from '../styled';
import { ResendVerificationEmail } from '../resendVerificationEmail';
import { FormContent } from './formContent';
import { initialValues, validationSchema } from './config';

export const LoginPage = () => {
    const history = useHistory();
    const [unverifiedEmail, setUnverifiedEmail] = useState('');
    const { executeRecaptcha } = useGoogleReCaptcha();

    const onLogout = () => setUnverifiedEmail('');

    const onLogin = async (values) => {
        let result;
        try {
            if (process.env.REACT_APP_PYCOM_ENABLE_RECAPTCHA === 'true') {
                const recaptchaResult = await executeRecaptcha('login');
                result = await login(values.email, values.password, recaptchaResult);
            } else {
                result = await login(values.email, values.password);
            }

            if (result?.status === 'Success') {
                showToastSuccess('Logged in');
                history.push(ROUTES.main);
            } else if (result?.errorMessage?.message === 'USER_IS_NOT_VERIFIED') {
                showToastInfo('Please, verify your email first!');
                setUnverifiedEmail(values.email);
            } else {
                showToastError('Bad credentials. Login attempt failed');
            }
        } catch (error) {
            showToastError('System error');
        }
    };

    const onClickRegister = () => {
        history.push(ROUTES.auth.register);
    };

    if (unverifiedEmail) {
        return <ResendVerificationEmail email={unverifiedEmail} onLogout={onLogout} />;
    }

    return (
        <>
            <RootStyled.Title>
                Log in to continue
            </RootStyled.Title>
            <Formik
                onSubmit={onLogin}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {(props) => <FormContent {...props} onClickRegister={onClickRegister} />}
            </Formik>
        </>
    );
};
